// [MEMO] 월 근무현황 인쇄
import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';

import { onLoadLeaveReport } from '../utils/onLoadLeaveReport';
import { ApprovalList } from '../../SP10-020-approval-table-setting/ApprovalList/ApprovalList'; // [MEMO] 결재 박스

/**
 * permitCode 권한 코드
 * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
 * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
 * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
 */

const LeaveReportPrinting = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const receivedObject = JSON.parse(queryParams.get('dataObject'));

  //console.log('🍕receivedObject: ', receivedObject);

  //const StatusIdx = receivedObject.idx;
  //const employeeNum = receivedObject.employeeNum;


  const [isListData, setIsListData] = useState(false);
  const [leaveReportData, setLeaveReportData] = useState<any[]>([]);
  const [dataContractName, setDataContractName] = useState('');

  const token = sessionStorage.getItem('token');
  const permitCode = sessionStorage.getItem('permitCode');
  const headOfficerManagerCode = process.env.HEAD_OFFICE_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentManagerCode = process.env.APARTMENT_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentNameAtSessionStorage = sessionStorage.getItem('apartmentName');

  useEffect(() => {
    onLoadLeaveReport({
      setLeaveReportData,
      StatusIdx: receivedObject.idx,
      employeeNum: receivedObject.empno,
    });
  }, []);
  const today = new Date();

  //console.log('leaveReportData: ', leaveReportData);
  //console.log('isListData: ', isListData);

  return (
    <>
      <ViewSection>
        <Button className="print" onClick={() => window.print()}>
          인쇄하기
        </Button>
        <Button className="close" onClick={() => window.close()}>
          닫기
        </Button>
      </ViewSection>
      <PrintSection>
        <TitleSection>
          <TitleBox>
              휴가원
          </TitleBox>
          {!isListData && (
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px', width: '100%', }} >
              <ApprovalList setIsListData={setIsListData} />
            </div>
          )}
          {isListData && (
            <Tables><tbody><TrBlank><TdBlank></TdBlank></TrBlank></tbody></Tables>
          )}
          <Tables>
          <tbody>
            <TrForHeader>
              <TdTitle>근 무</TdTitle>
              <TdContents colSpan="3">{leaveReportData.ContractName ? leaveReportData.ContractName : ''}</TdContents>
            </TrForHeader>
            <TrForHeader>
              <TdTitle>소 속</TdTitle>
              <TdContents colSpan="3">{leaveReportData.CorpName ? leaveReportData.CorpName : ''}</TdContents>
            </TrForHeader>
            <TrForHeader>
              <TdTitle>직 위</TdTitle>
              <TdContents>{leaveReportData.CategoryName ? leaveReportData.CategoryName : ''}</TdContents>
              <TdTitle>성 명</TdTitle>
              <TdContents>{leaveReportData.EmployeeName ? leaveReportData.EmployeeName : ''}</TdContents>
            </TrForHeader>
            <TrForHeader>
              <TdTitle rowSpan="2">기 간</TdTitle>
              <TdContentsHalf colSpan="2">{leaveReportData.LeaveStartDate&&leaveReportData.LeaveStartDate.slice(0, 4)}년 {leaveReportData.LeaveStartDate&&leaveReportData.LeaveStartDate.slice(5, 7)}월 {leaveReportData.LeaveStartDate&&leaveReportData.LeaveStartDate.slice(8, 10)}일 부터</TdContentsHalf>
              <TdContents rowSpan="2">( {leaveReportData.LeaveDay} ) 일간</TdContents>
            </TrForHeader>
            <TrForHeader>
              <TdContentsHalf colSpan="2">{leaveReportData.LeaveStartDate&&leaveReportData.LeaveEndDate.slice(0, 4)}년 {leaveReportData.LeaveStartDate&&leaveReportData.LeaveEndDate.slice(5, 7)}월 {leaveReportData.LeaveStartDate&&leaveReportData.LeaveEndDate.slice(8, 10)}일 까지</TdContentsHalf>
            </TrForHeader>
            <TrForHeader>
              <TdTitle>구분</TdTitle>
              <TdContents colSpan="3">{leaveReportData.LeaveType ? leaveReportData.LeaveType : ''}</TdContents>
            </TrForHeader>
            <TrForHeader>
              <TdTitleFull>사 유</TdTitleFull>
              <TdContentsFull colSpan="3">{leaveReportData.LeaveNote ? leaveReportData.LeaveNote : ''}</TdContentsFull>
            </TrForHeader>
            <TrForHeader>
              <TdContentsFooter colSpan="4">
                <Tables>
                <tbody>
                  <TrFooter><TdContentsFooter1>
                    위와 같이 휴가원을 제출합니다.
                  </TdContentsFooter1></TrFooter>
                  <TrFooter><TdContentsFooter2>
                  {leaveReportData.SummitDate&&leaveReportData.SummitDate.slice(0, 4)}년 {leaveReportData.SummitDate&&leaveReportData.SummitDate.slice(5, 7)}월 {leaveReportData.SummitDate&&leaveReportData.SummitDate.slice(8, 10)}일
                  </TdContentsFooter2></TrFooter>
                  <TrFooter><TdContentsFooter3>
                    제출인 {leaveReportData.EmployeeName ? leaveReportData.EmployeeName : ''} (인)
                  </TdContentsFooter3></TrFooter>
                </tbody>
                </Tables>
              </TdContentsFooter>
            </TrForHeader>
          </tbody>
          </Tables>

          <InfoBox>
            <div>
              단지명:
              {permitCode === apartmentManagerCode &&
                (apartmentNameAtSessionStorage
                  ? apartmentNameAtSessionStorage
                  : '')}
              {permitCode === headOfficerManagerCode &&
                (receivedObject.apartmentName
                  ? receivedObject.apartmentName
                  : '')}
            </div>
            <div>주식회사 앰앰아이</div>
          </InfoBox>
        </TitleSection>
      </PrintSection>
    </>
  );
};

export default LeaveReportPrinting;

const ViewSection = styled.div`
  position: fixed;
  top: 10px;
  left: 10px;

  /* 인쇄 시에만 출력할 스타일 지정 */
  @media print {
    display: none;
  }
`;

const PrintSection = styled.div`
  background-color: white;
  width: 210mm;
  height: 297mm;
  margin: 0;
  padding: 0.5cm;

  /* 인쇄 시에만 출력할 스타일 지정 */
  @media print {
    display: block;
    width: 210mm;
    height: 297mm;
    margin: 0;
  }

  /* 웹 페이지에서는 보이지 않도록 숨김 처리 */
  /* display: none; */
`;
const Button = styled.button`
  ${({ theme }) => theme.printBtnStyle};

  &.print {
    margin-right: 5px;
    width: 60px;
    height: 30px;
  }

  &.close {
    width: 60px;
    height: 30px;
  }
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing:20px;
  margin-top: 5mm;
  margin-bottom: 5.8mm; /* 🚨🚨🚨🚨🚨 결재 박스 관련(수정 후)2 */
`;

const InfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
`;

const Tables = styled.table`
  width: 100%;
`;

const TrForHeader = styled.tr`
  background-color: white;
`;

const TdTitle = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  line-height:2;
  letter-spacing:5px;
  border-top: solid 1px ${({ theme }) => theme.fontColor.black};
  border-right: solid 1px ${({ theme }) => theme.fontColor.black};
  border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
  border-left: solid 1px ${({ theme }) => theme.fontColor.black};
  width: 170px;
  height: 80px;
`;

const TdContents = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  line-height:2;
  letter-spacing:5px;
  border-top: solid 1px ${({ theme }) => theme.fontColor.black};
  border-right: solid 1px ${({ theme }) => theme.fontColor.black};
  border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
  border-left: solid 1px ${({ theme }) => theme.fontColor.black};
  height: 80px;
`;

const TdTitleFull = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  line-height:2;
  letter-spacing:5px;
  border-top: solid 1px ${({ theme }) => theme.fontColor.black};
  border-right: solid 1px ${({ theme }) => theme.fontColor.black};
  border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
  border-left: solid 1px ${({ theme }) => theme.fontColor.black};
  width: 170px;
  height: 360px;
`;

const TdContentsFull = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  line-height:2;
  letter-spacing:5px;
  white-space: pre-wrap;
  border-top: solid 1px ${({ theme }) => theme.fontColor.black};
  border-right: solid 1px ${({ theme }) => theme.fontColor.black};
  border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
  border-left: solid 1px ${({ theme }) => theme.fontColor.black};
  height: 360px;
`;

const TdContentsHalf = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  line-height:2;
  letter-spacing:5px;
  border-top: solid 1px ${({ theme }) => theme.fontColor.black};
  border-right: solid 1px ${({ theme }) => theme.fontColor.black};
  border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
  border-left: solid 1px ${({ theme }) => theme.fontColor.black};
  height: 40px;
`;

const TdContentsFooter = styled.td`
  font-size: 20px;
  text-align: center;
  vertical-align: middle;
  line-height:2.5;
  letter-spacing:5px;
  border-top: solid 1px ${({ theme }) => theme.fontColor.black};
  border-right: solid 1px ${({ theme }) => theme.fontColor.black};
  border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
  border-left: solid 1px ${({ theme }) => theme.fontColor.black};
  height: 180px;
`;

const TrFooter = styled.tr`
  background-color: white;
`;


const TdContentsFooter1 = styled.td`
  font-size: 20px;
  text-align: center;
  vertical-align: middle;
  line-height:2.5;
  letter-spacing:5px;
  height: 60px;
`;

const TdContentsFooter2 = styled.td`
  font-size: 20px;
  text-align: right;
  vertical-align: middle;
  padding: 0px 60px 0px 0px;
  padding-rigth: 150px;
  line-height:2.5;
  letter-spacing:5px;
  height: 60px;
`;

const TdContentsFooter3 = styled.td`
  font-size: 20px;
  text-align: right;
  padding: 0px 50px 0px 0px;
  vertical-align: middle;
  line-height:2.5;
  letter-spacing:5px;
  word-spacing:15px;
  height: 60px;
`;

const TrBlank = styled.tr`
  background-color: white;
`;


const TdBlank = styled.td`
  background-color: white;
  height: 60px;
`;
