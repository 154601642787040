// [MEMO] 주간 일정 수정을 위한 모달 컴포넌트
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { onModifyData } from '../utils/onModifyData';
import { onDeleteData } from '../utils/onDeleteData';

interface ModalContentsProps {
  closeModal: () => void;
  setIsUpdateStatus: Dispatch<SetStateAction<boolean>>;
  detailWorkData: any[];
  employeeNum: string;
  targetWorkStatusIdx: string | null;
  cellData: any; // 🔰
  selectedStatusIdx: string; // 🔰
  setSelectedStatusIdx: Dispatch<SetStateAction<string>>; // 🔰
}

export const ModalContents = ({
  closeModal,
  setIsUpdateStatus,
  detailWorkData,
  employeeNum,
  targetWorkStatusIdx,
  cellData, // 🔰
  selectedStatusIdx, // 🔰
  setSelectedStatusIdx, // 🔰
}: ModalContentsProps) => {
  //console.log('detailWorkData🍡🙏: ', detailWorkData);
  //console.log('cellData🍡🙏: ', cellData);

  // [TAG] 👇 (화면에 보이는) 시간 설정 -----start
  useEffect(() => {
    setSelectedStartHours(onSelectedStartHours());
    setSelectedStartMinutes(onSelectedStartMinutes());
    setSelectedEndHours(onSelectedEndHours());
    setSelectedEndMinutes(onSelectedEndMinutes());
  }, []);

  function onSelectedStartHours(): number {
    // if (cellData.timeToWork) {
    //   const hours = Number(cellData.timeToWork.slice(0, 2));
    //   return hours;
    // } else return selectedStartHours;

    if (detailWorkData[0].TriggerTimeStart) {
      const hours = Number(detailWorkData[0].TriggerTimeStart.slice(0, 2));
      return hours;
    } else return selectedStartHours;
  }

  function onSelectedStartMinutes(): number {
    // if (cellData.timeToWork) {
    //   const Minutes = Number(cellData.timeToWork.slice(3, 5));
    //   return Minutes;
    // } else return selectedStartMinutes;

    if (detailWorkData[0].TriggerTimeStart) {
      const Minutes = Number(detailWorkData[0].TriggerTimeStart.slice(3, 5));
      return Minutes;
    } else return selectedStartMinutes;
  }

  function onSelectedEndHours(): number {
    // if (cellData.timeToHome) {
    //   const hours = Number(cellData.timeToHome.slice(0, 2));
    //   return hours;
    // } else return selectedEndHours;

    if (detailWorkData[0].TriggerTimeEnd) {
      const hours = Number(detailWorkData[0].TriggerTimeEnd.slice(0, 2));
      return hours;
    } else return selectedEndHours;
  }

  function onSelectedEndMinutes(): number {
    // if (cellData.timeToHome) {
    //   const minutes = Number(cellData.timeToHome.slice(3, 5));
    //   return minutes;
    // } else return selectedEndMinutes;

    if (detailWorkData[0].TriggerTimeEnd) {
      const minutes = Number(detailWorkData[0].TriggerTimeEnd.slice(3, 5));
      return minutes;
    } else return selectedEndMinutes;
  }
  // [TAG] 👆 (화면에 보이는) 시간 설정 -----end

  // [MEMO] 👇 내일 날짜 표기
  const targetDate = new Date(detailWorkData[0].TriggerDate);
  const tomorrowDateObject = new Date(
    targetDate.getFullYear(),
    targetDate.getMonth(),
    targetDate.getDate() + 2
  );
  const tomorrowDate = tomorrowDateObject.toISOString().substring(0, 10);

  //console.log('detailWorkData🔪🍞🥗', detailWorkData);

  // [MEMO] 👇 다음 날 퇴근인지 체크 true면 다음 날까지 근무, false면 당일 근무
  const [isNextDay, setIsNextDay] = useState(
    detailWorkData[0].EndTimeDay === true ? true : false
  );

  function switchValidContract() {
    setIsNextDay(false);

    if (isNextDay === false) {
      setIsNextDay(true);
    }
  }

  // [TAG] 시작 시간
  const [selectedStartHours, setSelectedStartHours] = useState(0);
  const [selectedStartMinutes, setSelectedStartMinutes] = useState(0);

  const startHours = Array.from({ length: 24 }, (_, index) => index); // [MEMO] 0부터 23까지 숫자 배열 생성
  const startMinutes = Array.from({ length: 60 }, (_, index) => index); // [MEMO] 0부터 59까지 숫자 배열 생성

  const handleStartHourChange = (event: { target: { value: string } }) => {
    setSelectedStartHours(parseInt(event.target.value));
  };

  const handleStartMinuteChange = (event: { target: { value: string } }) => {
    setSelectedStartMinutes(parseInt(event.target.value));
  };

  // [TAG] 종료 시간
  const [selectedEndHours, setSelectedEndHours] = useState(0);
  const [selectedEndMinutes, setSelectedEndMinutes] = useState(0);

  const endHours = Array.from({ length: 24 }, (_, index) => index); // [MEMO] 0부터 23까지 숫자 배열 생성
  const endMinutes = Array.from({ length: 60 }, (_, index) => index); // [MEMO] 0부터 59까지 숫자 배열 생성

  const handleEndHourChange = (event: { target: { value: string } }) => {
    setSelectedEndHours(parseInt(event.target.value));
  };

  const handleEndMinuteChange = (event: { target: { value: string } }) => {
    setSelectedEndMinutes(parseInt(event.target.value));
  };

  /**
   * [MEMO] 근무 종료일이 다음날인지 여부에 따라,'변경 후'에 있는 드롭박스 배열(시간, 분) 조절하기
   * 1. availableEndHours: 선택 가능한 종료 '시간'
   * 1-1. if (!isNextDay) 조건의 의미:
   * - !isNextDay(=당일 퇴근)의 경우,
   * 시작 시간(=selectedStartHours) 이후 시간만 선택 가능
   * 2. availableEndMinutes: 선택 가능한 종료 '분'
   * 2-1. if (!isNextDay && selectedStartHours < selectedEndHours) 조건의 의미:
   * - !isNextDay(=당일 퇴근) + 선택한 종료 시간(=selectedEndHours)이 시작 시간(=selectedStartHours)보다 클 때,
   * - endMinutes 전부(=0~59분) 선택 가능
   * 2-2. if (!isNextDay) 조건의 의미:
   * - !isNextDay(=당일 퇴근) + 선택한 종료 시간(=selectedEndHours)이 시작 시간(=selectedStartHours)과 같을 때,
   * - 시작 분(=selectedStartMinutes) 이후 분만 선택 가능
   */
  let availableEndHours: number[] = endHours;
  let availableEndMinutes: number[] = endMinutes;

  if (!isNextDay) {
    availableEndHours = endHours.slice(selectedStartHours);
  } else if (isNextDay) {
    availableEndHours = endHours;
  }

  if (!isNextDay && selectedStartHours < selectedEndHours) {
    availableEndMinutes = endMinutes;
  } else if (!isNextDay) {
    availableEndMinutes = endMinutes.slice(selectedStartMinutes);
  } else if (isNextDay) {
    availableEndMinutes = endMinutes;
  }

  // ------------ 🔰
  const [selectedStatusCode, setSelectedStatusCode] = useState('');
  const [selectedStatusType, setSelectedStatusType] = useState('');

  const onChangeStatusValue = e => {
    const selectedIdx = e.target.value; // idx
    const selectedOption = cellData.statusInfo.find(
      d => Number(d.statusIdx) === Number(selectedIdx)
    );

    // console.log('onChangeStatusValue/인덱스-selectedIndex: ', selectedIdx);
    // console.log('onChangeStatusValue/옵션-selectedOption: ', selectedOption);

    setSelectedStatusIdx(selectedIdx);
    setSelectedStatusCode(selectedOption.statusCode);

    if (selectedOption.statusCode === 'W1000') {
      setSelectedStatusType('D');
    }
  };

  //console.log('🍞333🍞selectedStatusCode: ', selectedStatusCode);
  //console.log('🍞4444🍞selectedStatusType: ', selectedStatusType);

  // ------------ 🔰
  const [isBeforeHalfDayOff, setIsBeforeHalfDayOff] = useState(false);
  const [isAfterHalfDayOff, setIsAfterHalfDayOff] = useState(false);
  const [isAllDayOff, setIsAllDayOff] = useState(false);


  function switchBeforeHalfDayOff() {
    if (isBeforeHalfDayOff === false) {
      setIsBeforeHalfDayOff(true);
      setIsAfterHalfDayOff(false);
      setIsAllDayOff(false);
      setSelectedStatusType('H');
    }
  }

  function switchAfterHalfDayOff() {
    if (isAfterHalfDayOff === false) {
      setIsBeforeHalfDayOff(false);
      setIsAfterHalfDayOff(true);
      setIsAllDayOff(false);
      setSelectedStatusType('T');
    }
  }

  // ---
  const [openCheckDelete, setOpenCheckDelete] = useState(false);
  const isDeleteData = () => {
    if (selectedStatusIdx) {
      setOpenCheckDelete(true);
    }
    else
    {
      alert('삭제할 근무가 선택되지 않았습니다\n\n근무 유형을 확인하고 선택해주세요.');

    }
  };
  const closeCheckDelete = () => {
    setOpenCheckDelete(false);
  };

  const [inputNote, setInputeNote] = useState(detailWorkData[0].AttedNote);

  const handleInputNote = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputeNote(e.target.value);
  };

  //console.log('[AC10-030-REPORT] 근무 현황(주간) 수정 요청: ',`${detailWorkData[0]}`  );
  //console.log('[AC10-030-REPORT] 근무 현황(주간) 수정 요청 - AttedNote: ',detailWorkData[0].AttedNote );
  return (
    <>
      {openCheckDelete && (
        <Background>
          <DeleteAlertModalWrap
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <div>
              삭제한 데이터는 복구할 수 없습니다. 계속 진행하시겠습니까?
            </div>
            <AlertModalBtnList>
              <ButtonStyle
                onClick={() =>
                  onDeleteData({
                    closeModal,
                    setIsUpdateStatus,
                    employeeNum,
                    selectedStatusIdx,
                    cellData,
                  })
                }
              >
                예
              </ButtonStyle>
              <ButtonStyle
                style={{ marginLeft: '5px' }}
                onClick={() => closeCheckDelete()}
              >
                아니오
              </ButtonStyle>
            </AlertModalBtnList>
          </DeleteAlertModalWrap>
        </Background>
      )}

      <Wrap>
        <ClassificationTitleBox>
          <CategoryTitle>근무 현황</CategoryTitle>
        </ClassificationTitleBox>

        <Section className="first-section">
          <CategoryTitleBox>
            <CategoryTitle>상태</CategoryTitle>
          </CategoryTitleBox>
          {/* <StatusNameBox>{detailWorkData[0].AttendStatusName}</StatusNameBox> */}
          <StatusNameBox>
            <label>
              <select
                onChange={onChangeStatusValue}
                style={{
                  width: '140px',
                }}
              >
                <option key="default" value="">
                  유형을 선택해주세요
                </option>
                {cellData.statusInfo.map(d => {
                  const reg = /W1000|H/;
                  if (reg.test(d.statusCode)) {
                    return (
                      <option key={d.statusIdx} value={d.statusIdx}>
                        {!d.statusIdx ? '' : d.statusName}
                      </option>
                    );
                  }
                })}
              </select>
            </label>
          </StatusNameBox>
        </Section>
        {selectedStatusCode == 'W1000' ? (
          <>
            <Section>
              <CategoryTitleBox>
                <CategoryTitle>변경 전</CategoryTitle>
              </CategoryTitleBox>
              <div style={{ display: 'flex' }}>
                <DateBox style={{ marginLeft: '5px' }}>
                  <p className="year">
                    {detailWorkData[0].TriggerDate.slice(0, 4)}년
                  </p>
                  <p className="month">
                    {detailWorkData[0].TriggerDate.slice(5, 7)}월
                  </p>
                  <p className="date">
                    {detailWorkData[0].TriggerDate.slice(8, 10)}일
                  </p>
                </DateBox>
                <TimeBox className="before-modify">
                  {detailWorkData[0].TriggerTimeStart}
                </TimeBox>
              </div>
              <p style={{ margin: '0 5px' }}>~</p>
              <div style={{ display: 'flex' }}>
                {detailWorkData[0].EndTimeDay ? (
                  <DateBox>
                    <p className="year">{tomorrowDate.slice(0, 4)}년</p>
                    <p className="month">{tomorrowDate.slice(5, 7)}월</p>
                    <p className="date">{tomorrowDate.slice(8, 10)}일</p>
                  </DateBox>
                ) : (
                  <DateBox>
                    <p className="year">
                      {detailWorkData[0].TriggerDate.slice(0, 4)}년
                    </p>
                    <p className="month">
                      {detailWorkData[0].TriggerDate.slice(5, 7)}월
                    </p>
                    <p className="date">
                      {detailWorkData[0].TriggerDate.slice(8, 10)}일
                    </p>
                  </DateBox>
                )}
                <TimeBox className="before-modify">
                  {detailWorkData[0].TriggerTimeEnd
                    ? detailWorkData[0].TriggerTimeEnd
                    : ''}
                </TimeBox>
              </div>
            </Section>
            <Section className="last-section">
              <CategoryTitleBox>
                <CategoryTitle>변경 후</CategoryTitle>
              </CategoryTitleBox>
              <div style={{ display: 'flex' }}>
                <DateBox style={{ marginLeft: '5px' }}>
                  <p className="year">
                    {detailWorkData[0].TriggerDate.slice(0, 4)}년
                  </p>
                  <p className="month">
                    {detailWorkData[0].TriggerDate.slice(5, 7)}월
                  </p>
                  <p className="date">
                    {detailWorkData[0].TriggerDate.slice(8, 10)}일
                  </p>
                </DateBox>
                <TimeBox>
                  {selectedStartHours.toString().length === 1
                    ? '0' + selectedStartHours
                    : selectedStartHours}
                  :
                  {selectedStartMinutes.toString().length === 1
                    ? '0' + selectedStartMinutes
                    : selectedStartMinutes}
                </TimeBox>
              </div>
              <p style={{ margin: '0 5px' }}>~</p>
              <div style={{ display: 'flex' }}>
                {isNextDay ? (
                  <DateBox>
                    <p className="year">{tomorrowDate.slice(0, 4)}년</p>
                    <p className="month">{tomorrowDate.slice(5, 7)}월</p>
                    <p className="date">{tomorrowDate.slice(8, 10)}일</p>
                  </DateBox>
                ) : (
                  <DateBox>
                    <p className="year">
                      {detailWorkData[0].TriggerDate.slice(0, 4)}년
                    </p>
                    <p className="month">
                      {detailWorkData[0].TriggerDate.slice(5, 7)}월
                    </p>
                    <p className="date">
                      {detailWorkData[0].TriggerDate.slice(8, 10)}일
                    </p>
                  </DateBox>
                )}
                <TimeBox>
                  {detailWorkData[0].TriggerTimeEnd
                    ? `${
                        selectedEndHours.toString().length === 1
                          ? '0' + selectedEndHours
                          : selectedEndHours
                      }:${
                        selectedEndMinutes.toString().length === 1
                          ? '0' + selectedEndMinutes
                          : selectedEndMinutes
                      }`
                    : ''}
                </TimeBox>
              </div>
            </Section>
          </>
        ) : selectedStatusCode == 'H1000' || selectedStatusCode == 'H2000' || selectedStatusCode == 'H3000' ? (
          <>
            <Section>
              <CategoryTitleBox>
                <CategoryTitle>변경 전</CategoryTitle>
              </CategoryTitleBox>
              <div style={{ display: 'flex', marginLeft: '5px', }} >
                <div style={{ marginLeft: '5px' }}>
                  <input
                    type="checkbox"
                    id={'check-all-day-off'}
                    style={{ margin: '0' }}
                    checked={detailWorkData[0].Working =='A' ? true : false }
                    readOnly
                  />
                  <label htmlFor={'check-all-day-off'} style={{ marginLeft: '3px', fontSize: '13px' }}>종 일</label>
                </div>
                <div style={{ marginLeft: '40px' }}>
                  <input
                    type="checkbox"
                    id={'check-half-day-off'}
                    style={{ margin: '0' }}
                    checked={detailWorkData[0].Working =='H' ? true : false }
                    readOnly
                  />
                  <label htmlFor={'check-half-day-off'} style={{ marginLeft: '3px', fontSize: '13px' }}>반 차 - 전</label>
                </div>
                <div style={{ marginLeft: '20px' }}>
                  <input
                    type="checkbox"
                    id={'check-half-day-off'}
                    style={{ margin: '0' }}
                    checked={detailWorkData[0].Working =='T' ? true : false }
                    readOnly
                  />
                  <label htmlFor={'check-half-day-off'} style={{ marginLeft: '3px', fontSize: '13px' }}>반 차 - 후</label>
                </div>
              </div>
            </Section>
            <Section className="last-section">
              <CategoryTitleBox>
                <CategoryTitle>변경 후</CategoryTitle>
              </CategoryTitleBox>
              <div style={{ display: 'flex', marginLeft: '5px', }} >
                <div style={{ marginLeft: '5px' }}>
                  <input
                    type="checkbox"
                    id={'check-all-day-off'}
                    style={{ margin: '0' }}
                    checked={isAllDayOff}
                    readOnly
                  />
                  <label htmlFor={'check-all-day-off'} style={{ marginLeft: '3px', fontSize: '13px' }}>종 일</label>
                </div>
                <div style={{ marginLeft: '40px' }}>
                  <input
                    type="checkbox"
                    id={'check-half-day-off'}
                    style={{ margin: '0' }}
                    checked={isBeforeHalfDayOff}
                    readOnly
                  />
                  <label htmlFor={'check-half-day-off'} style={{ marginLeft: '3px', fontSize: '13px' }}>반 차 - 전</label>
                </div>
                <div style={{ marginLeft: '20px' }}>
                  <input
                    type="checkbox"
                    id={'check-half-day-off'}
                    style={{ margin: '0' }}
                    checked={isAfterHalfDayOff}
                    readOnly
                  />
                  <label htmlFor={'check-half-day-off'} style={{ marginLeft: '3px', fontSize: '13px' }}>반 차 - 후</label>
                </div>
              </div>
            </Section>
          </>
        ) : (
          <>
            <Section>
              <CategoryTitleBox>
                <CategoryTitle>변경 전</CategoryTitle>
              </CategoryTitleBox>
              <div style={{ display: 'flex', marginLeft: '5px', }} >
                <label htmlFor={'check-half-day-off'} style={{ marginLeft: '3px', fontSize: '13px' }} >
                  유형을 선택하세요.
                </label>
              </div>
            </Section>
            <Section className="last-section">
              <CategoryTitleBox>
                <CategoryTitle>변경 후</CategoryTitle>
              </CategoryTitleBox>
              <div style={{ display: 'flex', marginLeft: '5px', }} >
                <label htmlFor={'check-half-day-off'} style={{ marginLeft: '3px', fontSize: '13px' }} >
                  유형을 선택하세요.
                </label>
              </div>
            </Section>
          </>
        )}

        <ClassificationTitleBox>
          <CategoryTitle>수정 사항</CategoryTitle>
        </ClassificationTitleBox>

        {selectedStatusCode == 'W1000' ? (
          <>
            <Section
              className={
                detailWorkData[0].TriggerTimeEnd
                  ? 'after-modify-start-time'
                  : 'after-modify-start-time none-end-time'
              }
            >
              <CategoryTitleBox>
                <CategoryTitle>시작 시간</CategoryTitle>
              </CategoryTitleBox>
              <TimeBoxList className="start-time">
                <AfterModifyTimeBox>
                  <div>
                    <LabelStyle className="hours start-time">
                      {/* Start Hour: */}
                      <SelectStyle
                        value={selectedStartHours}
                        onChange={handleStartHourChange}
                      >
                        {startHours.map(hour => (
                          <option key={hour} value={hour}>
                            {hour}
                          </option>
                        ))}
                      </SelectStyle>
                    </LabelStyle>
                  </div>
                  <CategoryTitle className="hours select-box">시</CategoryTitle>
                </AfterModifyTimeBox>
                <AfterModifyTimeBox>
                  <div>
                    <LabelStyle className="start-time">
                      {/* Start Minute: */}
                      <SelectStyle
                        value={selectedStartMinutes}
                        onChange={handleStartMinuteChange}
                      >
                        {startMinutes.map(minute => (
                          <option key={minute} value={minute}>
                            {minute}
                          </option>
                        ))}
                      </SelectStyle>
                    </LabelStyle>
                  </div>
                  <CategoryTitle
                    className={
                      detailWorkData[0].TriggerTimeEnd
                        ? 'select-box'
                        : 'select-box none-end-time'
                    }
                  >
                    분
                  </CategoryTitle>
                </AfterModifyTimeBox>
              </TimeBoxList>
            </Section>
            {detailWorkData[0].TriggerTimeEnd ? (
              <Section className="after-modify-end-time">
                <CategoryTitleBox>
                  <CategoryTitle>종료 시간</CategoryTitle>
                </CategoryTitleBox>

                <TimeBoxList className="end-time">
                  <AfterModifyTimeBox>
                    <div>
                      <LabelStyle className="hours end-time">
                        {/* End Hour: */}
                        <SelectStyle
                          value={selectedEndHours}
                          onChange={handleEndHourChange}
                        >
                          {/* {endHours.map(hour => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))} */}
                          {availableEndHours.map(hour => (
                            <option key={hour} value={hour}>
                              {hour}
                            </option>
                          ))}
                        </SelectStyle>
                      </LabelStyle>
                    </div>
                    <CategoryTitle className="hours select-box">
                      시
                    </CategoryTitle>
                  </AfterModifyTimeBox>

                  <AfterModifyTimeBox>
                    <div>
                      <LabelStyle className="end-time">
                        {/* End Minute: */}
                        <SelectStyle
                          value={selectedEndMinutes}
                          onChange={handleEndMinuteChange}
                        >
                          {/* {endMinutes.map(minute => (
                      <option key={minute} value={minute}>
                        {minute}
                      </option>
                    ))} */}
                          {availableEndMinutes.map(minute => (
                            <option key={minute} value={minute}>
                              {minute}
                            </option>
                          ))}
                        </SelectStyle>
                      </LabelStyle>
                    </div>
                    <CategoryTitle className="select-box">분</CategoryTitle>
                  </AfterModifyTimeBox>
                </TimeBoxList>
                <NextDayCheckBox>
                  <input
                    type="checkbox"
                    id={'check-invalid-contract'}
                    style={{ margin: '0' }}
                    checked={isNextDay}
                    onChange={() => switchValidContract()}
                  />
                  <label
                    htmlFor={'check-invalid-contract'}
                    style={{ marginLeft: '3px', fontSize: '13px' }}
                  >
                    익일
                  </label>
                </NextDayCheckBox>
              </Section>
            ) : (
              ''
            )}
          </>
        ) : selectedStatusCode == 'H1000' || selectedStatusCode == 'H2000' || selectedStatusCode == 'H3000' ? (
          <>
          <Section className="target-type">
            <CategoryTitleBox>
              <CategoryTitle>구 분</CategoryTitle>
            </CategoryTitleBox>
            <div style={{ display: 'flex', marginLeft: '5px', }} >
              <div style={{ marginLeft: '5px' }}>
                <input type="checkbox" id={'check-all-day-off'} style={{ margin: '0' }} checked={isAllDayOff} onChange={() => switchAllDayOff()} />
                <label htmlFor={'check-all-day-off'} style={{ marginLeft: '3px', fontSize: '13px' }}>종 일</label>
              </div>
              <div style={{marginLeft: '40px', marginBottom: '5px', fontSize: '13px',fontWeight: '600',}} >
                  <input type="checkbox" id={'check-half-day-off'} style={{ margin: '0' }} checked={isBeforeHalfDayOff} onChange={() => switchBeforeHalfDayOff()} />
                  <label htmlFor={'check-half-day-off'} style={{ marginLeft: '3px', fontSize: '13px' }}>반 차 - 전</label>
              </div>
              <div style={{marginLeft: '20px', marginBottom: '5px', fontSize: '13px',fontWeight: '600',}} >
                <input type="checkbox" id={'check-half-day-off'} style={{ margin: '0' }} checked={isAfterHalfDayOff} onChange={() => switchAfterHalfDayOff()} />
                <label htmlFor={'check-half-day-off'} style={{ marginLeft: '3px', fontSize: '13px' }}>반 차 - 후</label>
              </div>
            </div>
          </Section>
          </>
        ) : (
          <>
            <Section style={{ width: '480px', height: '80px' }}>
              <div style={{ display: 'flex', marginLeft: '5px', }} >
                <label htmlFor={'check-half-day-off'} style={{ marginLeft: '3px', fontSize: '13px' }} >
                  유형을 선택하세요.
                </label>
              </div>
            </Section>
          </>
        )}
        <Section className="after-modify-end-time">
          <CategoryTitleAreaBox>
            <CategoryTitle>사유/비고</CategoryTitle>
          </CategoryTitleAreaBox>
          <TimeBoxList className="time-note">
            <TextAreaStyle
              defaultValue={inputNote}
              id={'check-invalid-contract'}
              placeholder={'추가 / 변경 사유를 입력해주세요.'}
              onChange={e => handleInputNote(e)}
            />
          </TimeBoxList>
        </Section>
        <ResultBtnList>
          <ButtonStyle
            className="modify"
            onClick={() =>
              onModifyData({
                closeModal,
                setIsUpdateStatus,
                detailWorkData,
                employeeNum,
                targetWorkStatusIdx,
                isNextDay,
                inputNote,
                selectedStartHours: selectedStartHours.toString(),
                selectedStartMinutes: selectedStartMinutes.toString(),
                selectedEndHours: selectedEndHours.toString(),
                selectedEndMinutes: selectedEndMinutes.toString(),
                selectedStatusIdx, // 🔰
                selectedStatusType, // 🔰🔰
              })
            }
          >
            수정
          </ButtonStyle>
          <ButtonStyle
            className="delete"
            onClick={() => isDeleteData()}
          >
            삭제
          </ButtonStyle>
          <ButtonStyle className="close" onClick={closeModal}>
            닫기
          </ButtonStyle>
        </ResultBtnList>
      </Wrap>
    </>
  );
};

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.7); */
  z-index: 999;
`;

const Container = styled.div`
  padding: 10px;
  border-radius: 8px;
  background: white;

  border: solid 1px gray;
  font-size: 13px;
`;

const DeleteAlertModalWrap = styled.div`
  padding: 10px;
  border-radius: 8px;
  border: solid 1px gray;
  background: white;
  box-shadow: 0px 5px 10px -3px ${({ theme }) => theme.backgroundColor.shadowGray};
  font-size: 13px;
`;

const AlertModalBtnList = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

const Wrap = styled.div`
  padding: 15px;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};

  &.first-section {
    border-top: none;
    border-bottom: none;
  }

  &.last-section {
    border-top: none;
    border-bottom: none;
  }

  &.after-modify-start-time {
    border-top: none;
  }

  &.after-modify-start-time.none-end-time {
    /* border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em; */
  }

  &.after-modify-end-time {
    border-top: none;
  }
`;

const ClassificationTitleBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 0;
  background-color: ${({ theme }) => theme.backgroundColor.gray};
`;

const CategoryTitleBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  width: 80px;
  height: 40px;
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.gray};
`;

const CategoryTitleAreaBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  width: 80px;
  height: 90px;
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.gray};
`;

const CategoryTitle = styled.div`
  color: ${({ theme }) => theme.fontColor.gray};
  font-size: 13px;
  font-weight: 500;

  &.hours.select-box {
    margin-left: 5px;
    margin-right: 10px;
  }

  &.select-box {
    margin-left: 5px;
  }

  &.select-box.none-end-time {
    margin-right: 5px;
  }
`;

const TextAreaStyle = styled.textarea`
  padding: 0 5px;
  width: 350px;
  height: 80px;
  border-radius: 5px;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};

  ::placeholder {
    margin: 0;
    /* color: #999; */
    color: ${({ theme }) => theme.fontColor.lightGray};
    font-size: 13px;
    /* font-style: italic; */
  }
`;

const StatusNameBox = styled.div`
  margin-left: 5px;
  font-size: 13px;
`;

const DateBox = styled.div`
  display: flex;
  font-size: 13px;

  p {
    &.year {
      margin-right: 5px;
    }

    &.month {
      margin-right: 5px;
    }
  }
`;

const TimeBox = styled.div`
  margin-left: 5px;
  font-size: 13px;
`;

const NextDayCheckBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: 10px;
`;

const LabelStyle = styled.label`
  display: block;
  font-weight: bold;
`;

const SelectStyle = styled.select`
  margin: 0;
  padding: 0;
  width: 150px;
  height: 30px;
  border-radius: 0.5em;
  border: 1px solid ${({ theme }) => theme.backgroundColor.shadowGray};
  font-size: 13px;
`;

const TimeBoxList = styled.div`
  display: flex;
  margin-left: 5px;
`;

const AfterModifyTimeBox = styled.div`
  display: flex;
  align-items: center;
`;

const ResultBtnList = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const ButtonStyle = styled.button`
  ${({ theme }) => theme.printBtnStyle};
  width: 80px;

  &.modify {
    /* border: 1px solid #0848d1;
    background-color: #266bff;
    color: white; */
  }

  &.delete {
    margin-left: 5px;
    border: 1px solid #ff2626;
    background-color: #ff5959;
    color: white;

    :hover {
      background-color: #ff2626;
    }
  }

  &.close {
    /* margin-left: 10px; */
    margin-left: 5px;
  }
`;
