import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import Styled from 'styled-components';
import axios from 'axios';
import { onLoadList } from '../utils/onLoadList';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

// interface ApprovalListProps {
//   listData: any[];
//   userList: any[];
// }

interface ApprovalListProps {
  setIsListData: Dispatch<SetStateAction<boolean>>;
}

export const ApprovalList = ({ setIsListData }: ApprovalListProps) => {
  useEffect(() => {
    fetchLoadList();
  }, []);

  const [listData, setListData] = useState([]);
  const [inputList, setInputList] = useState<string[]>([]);

  const fetchLoadList = async () => {
    const token = sessionStorage.getItem('token');
    const approvalIdx = sessionStorage.getItem('apartmentRefIdx');
    // const approvalIdx = '1';

    await axios
      .get(
        // https://dev.mmigroup.co.kr:8020/common/approval/approvalwebadmin/1
        `${process.env.COMMON_URL}/common/approval/approvalwebadmin/${approvalIdx}`,
        {
          headers: { tokenID: token },
        }
      )
      .then(res => {
        console.log('ApprovalList:',`${process.env.COMMON_URL}/common/approval/approvalwebadmin/${approvalIdx}`);
        try {
          if (res.data.ErrorCode === 0) {
            // ApprovalList 컴포넌트
            // console.log('결재박스ApprovalList.tsx-res.data.Data: ', res.data.Data);
            setListData(res.data.Data);
            setInputList(
              res.data.Data[0].ApprovalWebData.map(d => d.ApprovalName)
            );
          }
          incorrectToken(res);
        } catch (e) {
          console.error(e);
          console.log(
            `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
          );
        }
      });
  };
  // fetchLoadList();
  // console.log('결재박스ApprovalList.tsx-listData: ', listData);
  // console.log('결재박스ApprovalList.tsx-inputList: ', inputList);

  // if (listData[0]) {
  //   if (listData[0].ApprovalWebData.every(data => data.ApprovalName === '')) {
  //     return;
  //   }

  if (listData[0]) {
    if (listData[0].ApprovalWebData.every(data => data.ApprovalName === '')) {
      console.log('🚀🚀🚀111');
      return setIsListData(true);
      return;
    }
  } else {
    setIsListData(false);
  }

  return (
    <div className="box-container" style={{ display: 'flex' }}>
      {listData[0] &&
        listData[0].ApprovalWebData.map((user, index) => (
          <ApprovalBox
            key={index}
            className={`box-${index} ${user.ApprovalName ? 'on' : 'off'}`}
          >
            <ApprovalNameBox className={`${user.ApprovalName ? 'on' : 'off'}`}>
              {user.ApprovalName}
            </ApprovalNameBox>
          </ApprovalBox>
        ))}
    </div>
  );
};

const ApprovalBox = Styled.div`
  display: flex;
  align-items:start;
  width: 68px;
  height: 90px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  font-size: 13px;

  &.box-3 {
    border-right: solid 1px black;
    border-left: solid 1px black;
  }

  &.off {
    background-color: white;
    border: solid 1px white;
  }
`;

const ApprovalNameBox = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 5px;
  border-bottom: 1px solid black;

  ::placeholder {
    color: ${({ theme }) => theme.fontColor.lightGray};
  }

  &.off {
    border-bottom: 1px solid white;
  }
`;
