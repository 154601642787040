import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { onApartmentSerialNumberList } from '../../../lib/utils/onApartmentSerialNumberList'; // ref가 있는 인덱스 API (=단지 고유 번호(계약 아님))
import { Modal } from '../../apartmentSerialNumberSelectModal/Modal'; // ref가 있는 인덱스 API (=단지 고유 번호(계약 아님))
import { CreateApprovalTable } from '../CreateApprovalTable/CreateApprovalTable';
import { Button } from '../../UI/Button/Long';

export const Contents = () => {
  const permitCode = sessionStorage.getItem('permitCode');
  const apartmentRefIdx = sessionStorage.getItem('apartmentRefIdx'); // ref 있음 = 일반 인덱스, 아파트 고유 인덱스(계약 아님)
  const headOfficerManagerCode = process.env.HEAD_OFFICE_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentManagerCode = process.env.APARTMENT_MANAGER_CODE; // 권한에 따른 UI 변경 관련

  const [createApprovalTableVisible, setCreateApprovalTableVisible] =
    useState(false);

  useEffect(() => {
    setCreateApprovalTableVisible(true);
  }, []);

  const [onModal, setOnModal] = useState(false); // MEMO: 아파트 계약 정보 리스트가 있는 모달
  const [apartmentListData, setApartmentListData] = useState<any[]>([]); // MEMO: 아파트 계약 정보 API
  const [apartmentIdx, setApartmentIdx] = useState(apartmentRefIdx); // 아파트 리스트 인덱스
  const [apartmentName, setApartmentName] = useState('');

    const closeModal = () => {
    setOnModal(false);
  };
  //console.log('SP10-020 결재선 설정 - approvalIdx: ', apartmentIdx);
  return (
    <>
      {permitCode === headOfficerManagerCode && onModal && (
        <Modal
          closeModal={closeModal}
          apartmentListData={apartmentListData}
          setApartmentListData={setApartmentListData}
          apartmentIdx={apartmentIdx}
          setApartmentIdx={setApartmentIdx}
          apartmentName={apartmentName}
          setApartmentName={setApartmentName}
        />
      )}
      <Title>결재선 관리</Title>

      <SubTitle>
        <SubTitleCategory
          className={`${
            createApprovalTableVisible ? 'on-button' : 'off-button'
          }`}
          // onClick={() => onSwitchDailySection()}
        >
          기본 설정
        </SubTitleCategory>

        {/* <SubTitleCategory
          className={`${weeklySection ? 'on-button' : 'off-button'}`}
          onClick={() => onSwitchWeeklySection()}
        >
          주간
        </SubTitleCategory>
        <SubTitleCategory
          className={`${monthlySection ? 'on-button' : 'off-button'}`}
          onClick={() => onSwitchMonthlySection()}
        >
          월간
        </SubTitleCategory> */}
      </SubTitle>
      <Bar />

      <Section>
        <LoadInfoSection>
          {permitCode === headOfficerManagerCode && (
            <div style={{ display: 'flex', marginLeft: '5px',}}>
              <Button
                label="단지 선택"
                onClick={() =>
                  onApartmentSerialNumberList({
                    apartmentListData,
                    setApartmentListData,
                    setOnModal,
                  })
                }
              />
              <ApartmentNameBox>
                <ApartmentIdx>{apartmentIdx=='0'?'':apartmentIdx}</ApartmentIdx>
                <div>{apartmentName}</div>
              </ApartmentNameBox>
            </div>
          )}
          {/* <SaveBtn style={{ backgroundColor: 'lightGray' }}>저장하기</SaveBtn> */}
        </LoadInfoSection>

        <Bar />

        <ContentsSection>
          {createApprovalTableVisible && <CreateApprovalTable approvalIdx={apartmentIdx} />}
        </ContentsSection>
      </Section>
    </>
  );
};

const Bar = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const Title = styled.div`
  ${({ theme }) => theme.contentsMainTitle};
  ${({ theme }) => theme.stopDrag};
`;

const SubTitle = styled.div`
  ${({ theme }) => theme.contentsSubTitle};
  ${({ theme }) => theme.stopDrag};
`;

const SubTitleCategory = styled.div`
  ${({ theme }) => theme.contentsSubTitleCategory};

  &.on-button {
    color: ${({ theme }) => theme.fontColor.black};
    position: relative;

    ::before {
      content: '';
      position: absolute;
      bottom: -20px;
      width: 80%;
      height: 6px;
      background-color: ${({ theme }) => theme.fontColor.black};
      left: 50%; /* MEMO: 가상 요소를 가운데로 이동시키기 */
      transform: translateX(-50%);
      // transform: translate(-50%, 50%);
    }
  }

  &.off-button {
    color: ${({ theme }) => theme.fontColor.lightGray};
  }

  &:active {
    color: #fc894d;
  }

  &:hover {
    color: ${({ theme }) => theme.mainColor.main};
  }
`;

const Section = styled.div`
  /* */
`;

const ContentsSection = styled.div`
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  padding: 15px 30px 0 30px;
  /* height: 40px; */
  font-size: 13px;
`;


const ApartmentNameBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;

  p {
    color: ${({ theme }) => theme.fontColor.darkGray};
  }
`;

const ApartmentIdx = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  color: ${({ theme }) => theme.mainColor.main};
`;

const LoadInfoSection = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0 30px;
  height: 40px;
  font-size: 13px;
`;
