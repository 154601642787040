import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface onLoadManagerListProps {
  setManagerData: Dispatch<SetStateAction<any[]>>;
  setOnManagerModal: Dispatch<SetStateAction<boolean>>;
  // apartmentData: any[];
  // setApartmentData: Dispatch<SetStateAction<any[]>>;
  // enteredNameValue: string;
  // requestSelectedAreaCode: string;
}

export const onLoadManagerList = async ({
  setManagerData,
  setOnManagerModal,
}: // apartmentData,
// setApartmentData,
// enteredNameValue, // 단지명
// 단지 번호
// requestSelectedAreaCode, // 지역 코드
onLoadManagerListProps) => {
  const token = sessionStorage.getItem('token');

  await axios
    .get(
      // https://apihr.mmigroup.co.kr:9020/common/refuser?ItemPerPage=0&ItemStartPage=0&JobGroupCode=1
      `${process.env.COMMON_URL}/common/refuser?ItemPerPage=0&ItemStartPage=0&JobGroupCode=1&Status=N`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      // console.log(
      //   '계약 단지 정보 API확인: ',
      //   `${process.env.COMMON_URL}/common/refuser?ItemPerPage=0&ItemStartPage=0&JobGroupCode=1`
      // );
      try {
        if (res.data.ErrorCode === 0) {
          setManagerData(res.data.Data);
          setOnManagerModal(true);
        }

        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
