import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';

interface onLoadBtnProps {
  apartmentData: any[];
  setApartmentData: Dispatch<SetStateAction<any[]>>;
  enteredNameValue: string;
  requestSelectedAreaCode: string;
}

export const onLoadBtn = async ({
  apartmentData,
  setApartmentData,
  enteredNameValue, // 단지명
  // 단지 번호
  requestSelectedAreaCode, // 지역 코드
}: onLoadBtnProps) => {
  const token = sessionStorage.getItem('token');

  await axios
    .get(
      // `/sample/SS10-020-apartment-info.json` // ✅ 목데이터 (단지 정보 (단지 '계약' 정보 아님!))
      `${process.env.COMMON_URL}/common/refcontractinfo?ItemPerPage=0&ItemStartPage=0${enteredNameValue}&Valid=1${requestSelectedAreaCode}`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      console.log(
        'API확인',
        `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0${enteredNameValue}&Valid=1${requestSelectedAreaCode}`
      );
      try {
        setApartmentData(res.data.Data);
        //console.log(apartmentData, 'res.data.Data');
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
