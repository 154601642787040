import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';

interface onLoadDetailWorkInfoProps {
  setDetailWorkData: Dispatch<SetStateAction<never[]>>;
  statusIdx: string;
  employeeNum: string;
}

export const onLoadDetailWorkInfo = async ({
  setDetailWorkData,
  statusIdx,
  employeeNum,
}: onLoadDetailWorkInfoProps) => {
  if (!statusIdx) return;

  const token = sessionStorage.getItem('token');

  await axios
    .get(
      // https://dev.mmigroup.co.kr:8020/attendance/ac10030/attendancereport/00000?idx=1111
      `${process.env.COMMON_URL}/attendance/ac10030/attendancereport/${employeeNum}?idx=${statusIdx}`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      //console.log('근무 상세 기록: ', `${process.env.COMMON_URL}/attendance/ac10030/attendancereport/${employeeNum}?idx=${statusIdx}` );
      try {
        setDetailWorkData(res.data.Data);
        //console.log('🎉🎉🥳setDetailWorkData(res.data.Data): ', res.data.Data);

        if (res.data.Data.length > 0) {
          return;
        }
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
