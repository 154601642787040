import { Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface onLoadListProps {
  setListData: Dispatch<SetStateAction<any[]>>;
  setUserList: Dispatch<SetStateAction<any[]>>;
  setInputList: Dispatch<SetStateAction<any[]>>;
}

export const onLoadList = async ({
  approvalIdx,
  setListData,
  setInputList,
}: onLoadListProps) => {
  const token = sessionStorage.getItem('token');
  //const approvalIdx = sessionStorage.getItem('apartmentRefIdx');
  //const approvalIdx = '1';
  //console.log('SP10-020 결재선 설정 - approvalIdx: ', approvalIdx);
  await axios
    .get(
      // https://dev.mmigroup.co.kr:8020/common/approval/approvalwebadmin/1
      `${process.env.COMMON_URL}/common/approval/approvalwebadmin/${approvalIdx}`,
      {
        headers: { tokenID: token },
      }
    )
    .then(res => {
      //console.log('SP10-020 결재선 설정 - 로드: ', `${process.env.COMMON_URL}/common/approval/approvalwebadmin/${approvalIdx}` );
      try {
        if (res.data.ErrorCode === 0) {
          //console.log(',,,,,,,,,res.data.Data: ', res.data.Data);
          setListData(res.data.Data);
          // setUserList(res.data.Data[0].ApprovalWebData);
          setInputList(
            res.data.Data[0].ApprovalWebData.map(d => d.ApprovalName)
          );
        }
        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
