/* eslint-disable prefer-const */
// 월간 스케줄러
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { minutesToHours } from '../../../../lib/utils/minutesToHours';
import { onLoadWorkRecordInfo } from '../../utils/onLoadWorkRecordInfo'; // 태그 기록 불러오기
import { onLoadDetailWorkInfo } from '../../utils/onLoadDetailWorkInfo'; // 근무 상세 기록 불러오기

import { RightClickModalAtExistent } from '../../RightClickModal/RightClickModalAtExistent'; // 데이터 있는 셀 우클릭시 모달 - 수정 관련
import { RightClickModalAtEmpty } from '../../RightClickModal/RightClickModalAtEmpty'; // 빈 셀 우클릭시 모달 - 신규 등록 관련
import { WorkRecordModal } from '../../WorkRecordModal/WorkRecordModal';
import { ModifyModal } from '../../ModifyModal/ModifyModal'; // 근무 시간 수정 관련 모달
import { AddModal } from '../../AddModal/AddModal'; // 근무 시간 추가 관련 모달

interface SchedulerProps {
  employeeData: any[];
  apartmentContractIdx: string;
  calYear: string;
  calMonth: string;
  setIsUpdateStatus: Dispatch<SetStateAction<boolean>>;
}

interface statusInfoType {
  idx?: string; // =statusIdx
  AttendStatusCode?: string; // =statusCode
  Working?: string; // =statusActionType
  AttendStatusName?: string; // =statusName
  TriggerTimeStart?: string; // =startTime
  TriggerTimeEnd?: string; // =endTime
}

/*
Property 'statusInfo' does not exist on type
'{
  date?: number | undefined;
  timeToWork?: string | undefined;
  timeToHome?: string | undefined;
  workingTime?: string | undefined;
  isNextDateTimeToHome?: boolean | undefined;
  statusCode?: string | undefined;
}'.ts(2339)
*/

interface statusInfoViewType {
  date?: number;
  timeToWork?: string;
  timeToHome?: string;
  workingTime?: string;
  isNextDateTimeToHome?: boolean;
  statusCode?: string;

  // startTime?: boolean;
  // endTime?: boolean;
  // statusActionType?: string;
}

console.clear();

export const Scheduler = ({
  employeeData,
  apartmentContractIdx,
  calYear,
  calMonth,
  setIsUpdateStatus,
}: SchedulerProps) => {
  // console.log('[AC10-030]조직원 근무-월간 employeeData: ', employeeData);

  // const MONTHS = ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월',];
  const DAYS = ['일', '월', '화', '수', '목', '금', '토'];

  /**
   * [MEMO] targetMonth:
   * Date객체의 월 인덱스는 0부터 시작하기 때문에, 데이터 피커에서 가져 온 달에서 1을 빼야 함
   */
  const targetYear = Number(calYear);
  const targetMonth = Number(calMonth) - 1;

  const date = new Date(targetYear, targetMonth);

  // const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  // const firstDayOfMonth = new Date(date).getDay(); // [MEMO] const firstDayOfMonth = new Date(...) 혹은 해당 firstDayOfMonth로도 요일 표현 가능

  const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

  const lastDayOfPreMonth = new Date(date.getFullYear(), date.getMonth(), 0 ).getDate();
  const lastDateOfMonthAgo = new Date(date.setMonth(date.getMonth() - 1));	// 한달 전
  const lastDateOfPreMonth = lastDateOfMonthAgo.getFullYear() + '-' + (('0' + (lastDateOfMonthAgo.getMonth() + 1)).slice(-2)) + '-' + lastDayOfPreMonth.toString();

  // console.log('Selected Date', targetYear, targetMonth, date);
  // console.log('LastDate Of PreMonth', lastDateOfMonthAgo, lastDateOfPreMonth, lastDayOfPreMonth);
  // [MEMO] headerArray: 헤더 & 화면에 나타나는 달 정보(날짜, 요일)
  let headerArray: {
    date: number;
    day: string;
    fulldate: string;
  }[] = [];
  headerArray.splice(0, headerArray.length);
  for (let i = 1; i <= lastDayOfMonth; i++) {
    const dayOfMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      i
    ).getDay();
    const fulldate = calYear + "-" + String(calMonth).padStart(2, "0") + "-" + String(i).padStart(2, "0") ;
    headerArray.push({
      date: i,
      // day: DAYS[(firstDayOfMonth + i - 1) % 7], // [MEMO] firstDayOfMonth로도 요일 표현 가능
      day: DAYS[dayOfMonth],
      fulldate : fulldate,
    });
  }

  /**
   * 변수명 변경
   * dataArray -> processedEmployeeData
   * (dataArray에 속한) dayArray -> dayData
   */
  let processedEmployeeData: {
    dayData: {
      // date?: number | undefined;
      // timeToWork?: string | undefined;
      // timeToHome?: string | undefined;
      // workingTime?: string | undefined;
      // isNextDateTimeToHome?: boolean | undefined;
      // statusCode?: string | undefined;

      date?: number | undefined;
      employeeName?: string | undefined;
      employeeNum?: string | undefined;
      timeToWork?: string | undefined;
      timeToHome?: string | undefined;
      workingDate?: string | undefined;
      workingTime?: string | undefined;
      isNextDateTimeToHome?: boolean | undefined;
      statusInfo?: {
        statusIdx?: number | undefined;
        statusCode?: string | undefined;
        statusActionType?: string | undefined;
        statusName?: string | undefined;
        startTime?: string | undefined;
        endTime?: string | undefined;
      };
      statusTardy?: boolean | undefined;
      statusLeave?: boolean | undefined;
      changedRecord?: string | undefined;
      deleteRecord?: boolean | undefined;

      // date?: number;
      // timeToWork?: string;
      // timeToHome?: string;
      // workingTime?: string;
      // isNextDateTimeToHome?: boolean; // 퇴근 날이 다음 날짜인지 체크 (true: 다음날 퇴근)
      // statusCode?: string; // 상태(근무, 연차 등) 코드
      // statusInfo: {};
    }[];
    employeeName?: string | undefined;
    employeeNum?: string | undefined;
    totalWorkingTime?: number | undefined; // [MEMO] 총 근무시간
  }[] = [];

  function loadEmployeeData() {
    employeeData.map(item => {
      let employeeDayArray: {
        date?: number;
        employeeName?: string;
        employeeNum?: string;
        timeToWork?: string;
        timeToHome?: string;
        workingTime?: string;
        isNextDateTimeToHome?: boolean;
        statusInfo?: {
          statusIdx?: number;
          statusCode?: string;
          statusActionType?: string;
          statusName?: string;
          startTime?: string;
          endTime?: string;
        };
        statusTardy?: boolean;
        statusLeave?: boolean;
        changedRecord?: string;
        deleteRecord?: boolean;
      }[] = [];
      let totalWorkingTime = 0; // [MEMO] 총 근무시간
      if (item.WorkingDate.length > 0)
      {
        let idx = 0;
        let isNextWorkToHome = {
          isNextWork :false,
          statusIdx: '',
          statusCode: '',
          statusActionType: '',
          statusName: '',
          startTime: '',
          endTime: '',
        };
        for (let processDate = 0; processDate < lastDayOfMonth; processDate++)
        {
          let resultDate;
          resultDate = {
            date: processDate + 1,
            employeeName: item.UserNameKR, // 🍟
            employeeNum: item.EmployeeNo, // 🍟
            timeToWork: item.WorkingDate[idx].WorkingData[0].TriggerTimeStart, //출근
            timeToHome: item.WorkingDate[idx].WorkingData[0].TriggerTimeEnd, // 퇴근
            workingDate: item.WorkingDate[idx].WorkingDate, // 근무 기준일
            workingTime: item.WorkingDate[idx].WorkingTime, // 근무시간
            isNextDateTimeToHome: false, // 퇴근 날이 다음 날짜인지 체크 (true: 다음날 퇴근)
            statusInfo: (function () {
              return item.WorkingDate[idx].WorkingData.map(
                (d: statusInfoType) => ({
                  statusIdx: d.idx,
                  statusCode: d.AttendStatusCode,
                  statusActionType: d.Working,
                  statusName: d.AttendStatusName,
                  startTime: d.TriggerTimeStart,
                  endTime: d.TriggerTimeEnd,
                })
              );
            })(),
            statusTardy: item.WorkingDate[idx].WorkingData[0].StatusTardy,
            statusLeave: item.WorkingDate[idx].WorkingData[0].StatusLeave,
            changedRecord: item.WorkingDate[idx].WorkingData[0].ChangedRecord,
            deleteRecord: item.WorkingDate[idx].WorkingData[0].DeleteRecord,
          };
          // 전달 말일 출근, 당월 1일 퇴근 근무 처리 부분
          if ( lastDateOfPreMonth == item.WorkingDate[idx].WorkingDate)
          {
            //console.log('이전 달 말일 데이터 있음', item.UserNameKR, idx, item.WorkingDate[idx]);
            for (let statusIdx = 0; statusIdx < resultDate.statusInfo.length; statusIdx++)
            {
              if ((resultDate.statusInfo[statusIdx].statusCode == 'W1000') && (resultDate.statusInfo[statusIdx].startTime != ''))
              {
                isNextWorkToHome.isNextWork = true;
                isNextWorkToHome.statusIdx = resultDate.statusInfo[statusIdx].statusIdx;
                isNextWorkToHome.statusCode = resultDate.statusInfo[statusIdx].statusCode;
                isNextWorkToHome.statusActionType = resultDate.statusInfo[statusIdx].statusActionType;
                isNextWorkToHome.statusName = resultDate.statusInfo[statusIdx].statusName;
                isNextWorkToHome.startTime = resultDate.statusInfo[statusIdx].startTime;
                isNextWorkToHome.endTime = resultDate.statusInfo[statusIdx].endTime;
                resultDate.statusInfo[statusIdx].endTime = '';
                //console.log('isNextWorkToHome: ', isNextWorkToHome);
                //console.log('statusInfo: ', resultDate.statusInfo[statusIdx]);
              }
            }
            if (idx < item.WorkingDate.length - 1) {
              idx++;
            }
          }
          if ( processDate + 1 === Number(item.WorkingDate[idx].WorkingDate.slice(-2)))
          {
            // [MEMO] 👇 총 근무 시간
            totalWorkingTime = totalWorkingTime + Number(item.WorkingDate[idx].WorkingTime);
            // [TAG] 👇 현재 시간 체크를 위한 조건 -----start
            //console.log('StartDate/EndDate: ', item.WorkingDate[idx].WorkingData[0].TriggerStart.slice(0,10), item.WorkingDate[idx].WorkingData[0].TriggerEnd.slice(0,10));
            if(isNextWorkToHome.isNextWork == true)
            {
              resultDate.statusInfo.unshift({
                statusIdx: isNextWorkToHome.statusIdx,
                statusCode: isNextWorkToHome.statusCode,
                statusActionType: isNextWorkToHome.statusActionType,
                statusName: isNextWorkToHome.statusName,
                startTime: '',
                endTime: isNextWorkToHome.endTime,
              }),
              isNextWorkToHome.isNextWork = false;
              isNextWorkToHome.statusIdx = '';
              isNextWorkToHome.statusCode = '';
              isNextWorkToHome.statusActionType = '';
              isNextWorkToHome.statusName = '';
              isNextWorkToHome.startTime = '';
              isNextWorkToHome.endTime = '';
            }
            if(item.WorkingDate[idx].WorkingData[0].TriggerStart.slice(0,10) == item.WorkingDate[idx].WorkingData[0].TriggerEnd.slice(0,10)) {
              resultDate.isNextDateTimeToHome = false;
            } else {
              resultDate.isNextDateTimeToHome = true;
              for (let statusIdx = 0; statusIdx < resultDate.statusInfo.length; statusIdx++)
              {
                if ((resultDate.statusInfo[statusIdx].statusCode == 'W1000') && (resultDate.statusInfo[statusIdx].startTime != ''))
                {
                  isNextWorkToHome.isNextWork = true;
                  isNextWorkToHome.statusIdx = resultDate.statusInfo[statusIdx].statusIdx;
                  isNextWorkToHome.statusCode = resultDate.statusInfo[statusIdx].statusCode;
                  isNextWorkToHome.statusActionType = resultDate.statusInfo[statusIdx].statusActionType;
                  isNextWorkToHome.statusName = resultDate.statusInfo[statusIdx].statusName;
                  isNextWorkToHome.startTime = resultDate.statusInfo[statusIdx].startTime;
                  isNextWorkToHome.endTime = resultDate.statusInfo[statusIdx].endTime;
                  resultDate.statusInfo[statusIdx].endTime = '';
                  //console.log('isNextWorkToHome: ', isNextWorkToHome);
                  //console.log('statusInfo: ', resultDate.statusInfo[statusIdx]);
                }
              }
            }
            if (idx < item.WorkingDate.length - 1) {
              idx++;
            }
          } else {
            if(isNextWorkToHome.isNextWork == true)
            {
              resultDate = {
                date: processDate + 1,
                employeeName: item.UserNameKR, // 🍟 근무자 이름
                employeeNum: item.EmployeeNo, // 🍟 근무자 사번
                statusName: '근무없음',
                workingDate: headerArray[processDate].fulldate, // 근무 기준일
                statusInfo: [{
                  statusIdx: isNextWorkToHome.statusIdx,
                  statusCode: isNextWorkToHome.statusCode,
                  statusActionType: isNextWorkToHome.statusActionType,
                  statusName: isNextWorkToHome.statusName,
                  startTime: '',
                  endTime: isNextWorkToHome.endTime,
                }],
                timeToWork: '',
                timeToHome: '',
                workingTime: '',
              };
              isNextWorkToHome.isNextWork = false;
              isNextWorkToHome.statusIdx = '';
              isNextWorkToHome.statusCode = '';
              isNextWorkToHome.statusActionType = '';
              isNextWorkToHome.statusName = '';
              isNextWorkToHome.startTime = '';
              isNextWorkToHome.endTime = '';
            }
            else
            {
              resultDate = {
                date: processDate + 1,
                employeeName: item.UserNameKR, // 🍟 근무자 이름
                employeeNum: item.EmployeeNo, // 🍟 근무자 사번
                statusName: '근무없음',
                workingDate: headerArray[processDate].fulldate, // 근무 기준일
                timeToWork: '',
                timeToHome: '',
                workingTime: '',
              };
            }
          }
          //console.log('근무 기록 있음 - resultDate: ', resultDate);
          employeeDayArray.push(resultDate);
        }
        idx = 0;
      } else {
        for (let processDate = 0; processDate < lastDayOfMonth; processDate++)
        {
          let resultDate = {
            date: processDate + 1,
            employeeName: item.UserNameKR, // 🍟 근무자 이름
            employeeNum: item.EmployeeNo, // 🍟 근무자 사번
            timeToWork: '',
            timeToHome: '',
            workingDate: headerArray[processDate].fulldate, // 근무 기준일
            statusName: '근무없음',
            workingTime: '',
          };
          //console.log('근무 기록 없음 - resultDate: ', resultDate);
          employeeDayArray.push(resultDate);
        }
      }
      let result = {
        dayData: employeeDayArray,
        employeeName: item.UserNameKR,
        employeeNum: item.EmployeeNo,
        totalWorkingTime: totalWorkingTime, // [MEMO] 총 근무시간
      };
      processedEmployeeData.push(result);
    });
  }

  loadEmployeeData();

  //console.log('🍖월간🍖employeeData: ', employeeData);
  //console.log('🍖월간🍖processedEmployeeData: ', processedEmployeeData);

  // [TAG] 👇 근무 시간 추가 -----start
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [rightClickModalVisibleAtEmpty, setRightClickModalVisibleAtEmpty] = useState(false);
  type isLeaveReportData = { isLeave : boolean | false; idx: number | 0; employeeNum: string | ''};
  const [isLeaveReport, setIsLeaveReport] = useState<isLeaveReportData | null>(null);

  const closeAddModal = () => {
    setAddModalVisible(false);
  };
  const onRightClick = (
    e: React.MouseEvent,
    existing : boolean,
    dayDataIndex :number,
    row: any,
    data: any,
    employeeNum: string
  ) => {
    let statusIdx = (data.dayData[dayDataIndex].statusInfo ? data.dayData[dayDataIndex].statusInfo[0].statusIdx : 0);
    //console.log('onRightClick - row: ', row);
    //console.log('onRightClick - data: ', data);
    //console.log('onRightClick - existing: ', existing);
    //console.log('onRightClick - dayDataIndex: ', dayDataIndex);
    //console.log('onRightClick - StatusIdx: ', statusIdx);
    setRowInfo(row);
    setCellData(data.dayData[dayDataIndex]);
    setTargetWorkStatusIdx(statusIdx);
    setTargetEmployeeNum(employeeNum);
    onLoadWorkRecordInfo({
      employeeNum,
      apartmentContractIdx,
      date : data.dayData[dayDataIndex].workingDate,
      workRecordData,
      setWorkRecordData,
      setOnWorkRecordModal,
    });
    onLoadDetailWorkInfo({
      setDetailWorkData,
      statusIdx,
      employeeNum,
    });
    const position = { x: e.clientX, y: e.clientY };
    setRightClickModalPosition(position);
    if(row.statusInfo)
    {
      setIsLeaveReport({isLeave : false, idx: 0, employeeNum: '0'});
      for (let i = 0; i < row.statusInfo.length; i++) {
        if (row.statusInfo[i].statusActionType =='A' || row.statusInfo[i].statusActionType =='H' || row.statusInfo[i].statusActionType =='T')
        {
          setIsLeaveReport({isLeave : true, idx: row.statusInfo[i].statusIdx, employeeNum: employeeNum});
          //console.log('Right Click - row : Action, Idx , isLeaveReport', row.statusInfo[i].statusActionType, row.statusInfo[i].statusIdx, isLeaveReport);
        }
      }
    }

    if( existing === true  )
    {
      setRightClickModalVisibleAtExistent(true);
    }
    else
    {
      setRightClickModalVisibleAtEmpty(true);
    }
  };


  // [TAG] 👇 근무 시간 수정 -----start
  const [modifyModalVisible, setModifyModalVisible] = useState(false);
  const [
    rightClickModalVisibleAtExistent,
    setRightClickModalVisibleAtExistent,
  ] = useState(false);

  const closeModifyModal = () => {
    setModifyModalVisible(false);
  };

  // [TAG] 👇 근태 기록 확인 -----start
  const [onWorkRecordModal, setOnWorkRecordModal] = useState(false);
  const [workRecordData, setWorkRecordData] = useState([]);

  const closeModal = () => {
    setOnWorkRecordModal(false);
  };
  // [TAG] 👆 근태 기록 확인-----end

  const [detailWorkData, setDetailWorkData] = useState([]);
  const [rowInfo, setRowInfo] = useState([]);
  const [cellData, setCellData] = useState({});
  const [rightClickModalPosition, setRightClickModalPosition] = useState({x: 0,y: 0,});
  const [targetEmployeeNum, setTargetEmployeeNum] = useState('');
  const [targetWorkStatusIdx, setTargetWorkStatusIdx] = useState<string | null>(null);

  const handleRightClickModal = () => {
    setRightClickModalVisibleAtExistent(false);
    setRightClickModalVisibleAtEmpty(false);
  };

  const handleStatusBarClick = (statusIdx : string,employeeNum: string) => {
    setTargetWorkStatusIdx(statusIdx)
    setTargetEmployeeNum(employeeNum);
    //console.log('handleStatusBarClick - data', data);
    console.log('handleStatusBarClick 1- statusIdx, employeeNum',statusIdx, employeeNum);

    onLoadDetailWorkInfo({
      setDetailWorkData,
      statusIdx,
      employeeNum,
    });
    console.log('handleStatusBarClick 2- statusIdx, employeeNum',statusIdx, employeeNum);
  };

  // -----
  const [selectedStatusIdx, setSelectedStatusIdx] = useState('');
  // const [selectedStatusCode, setSelectedStatusCode] = useState('');
  // console.log('💚월간1💚selectedStatusIdx::: ', selectedStatusIdx);
  // console.log('💚월간2💚selectedStatusCode::: ', selectedStatusCode);

  return (
    <>
      {rightClickModalVisibleAtExistent && (
        <RightClickModalAtExistent
          closeModal={handleRightClickModal}
          setOnWorkRecordModal={setOnWorkRecordModal}
          setModifyModalVisible={setModifyModalVisible}
          position={rightClickModalPosition}
          workRecordData={workRecordData}
          detailWorkData={detailWorkData}
          rowInfo={rowInfo}
          isLeave={isLeaveReport}
          setAddModalVisible={setAddModalVisible}
          //cellData={cellData}
        />
      )}

      {rightClickModalVisibleAtEmpty && (
        <RightClickModalAtEmpty
          closeModal={handleRightClickModal}
          setAddModalVisible={setAddModalVisible}
          position={rightClickModalPosition}
          detailWorkData={detailWorkData}
          dailySection='0'
          rowInfo={rowInfo}
          //cellData={cellData} // 💥
        />
      )}

      {modifyModalVisible && (
        <ModifyModal
          closeModal={closeModifyModal}
          setRightClickModalVisible={setRightClickModalVisibleAtExistent}
          setIsUpdateStatus={setIsUpdateStatus}
          detailWorkData={detailWorkData}
          employeeNum={targetEmployeeNum}
          targetWorkStatusIdx={selectedStatusIdx}     // 추가
          //calYear={calYear}                         // 제거
          //calMonth={calMonth}                       // 제거
          selectedStatusIdx={selectedStatusIdx}
          setSelectedStatusIdx={setSelectedStatusIdx}
          cellData={cellData}
        />
      )}

      {addModalVisible && (
        <AddModal
          closeModal={closeAddModal}
          setRightClickModalVisible={setRightClickModalVisibleAtEmpty}
          setIsUpdateStatus={setIsUpdateStatus}
          detailWorkData={detailWorkData}             // 추가
          cellData={cellData}
          employeeNum={targetEmployeeNum}
          targetWorkStatusIdx={targetWorkStatusIdx}   // 추가
          calYear={calYear}
          calMonth={calMonth}
        />
      )}

      {onWorkRecordModal && (
        workRecordData.length > 0 ?
        (
          <WorkRecordModal
            closeModal={closeModal}
            workRecordData={workRecordData}
          />
        ) : (
          alert('태그 기록이 없습니다')
        )
      )}
      <Bar />
      <TableWrap>
        <Tables>
          <THead>
            <tr>
              <ThEmployeeNameHeader>{/* 근무자 */}</ThEmployeeNameHeader>
              <ThTotalWorkingTimeHeader>
                {/* 총 근무시간 */}
                <div />
              </ThTotalWorkingTimeHeader>

              {headerArray.map((data, index) => (
                <ThDateHeader
                  key={index}
                  className={`${
                    index === lastDayOfMonth - 1 ? 'last-date' : ''
                  }`}
                >
                  {data.day === '일' ? (
                    <>
                      <div className="sunday">{data.date}</div>
                      <p className="sunday">{data.day}</p>
                    </>
                  ) : (
                    <>
                      <div>{data.date}</div>
                      <p>{data.day}</p>
                    </>
                  )}
                </ThDateHeader>
              ))}
            </tr>
          </THead>

          <tbody>
            {processedEmployeeData.map((row, index) => (
              <TrForBody key={index}>
                <TdEmployeeName key={row.employeeName}>{row.employeeName}</TdEmployeeName>

                {/* 👇 총 근무시간 */}
                <TdTotalWorkingTime key={row.employeeNum}>
                  {row.totalWorkingTime > 0 ? (
                    <div>
                      <p>{minutesToHours(row.totalWorkingTime)} h</p>
                    </div>
                  ) : (
                    <div />
                  )}
                </TdTotalWorkingTime>
                {/* 👆 총 근무시간 */}

                {row.dayData.map((data, dayDataIndex) => {
                  return (
                    <TdTimeInfo
                    key={dayDataIndex}
                    className={`${
                      dayDataIndex === lastDayOfMonth - 1
                        ? 'last-date'
                        : ''
                    }`}
                    onClick={() => handleStatusBarClick((data.statusInfo ? data.statusInfo[0].statusIdx : 0), row.employeeNum)}
                    onContextMenu={e => {
                      e.preventDefault(),
                        onRightClick(
                          e,
                          (data.statusInfo ? true : false),
                          dayDataIndex,
                          data,
                          row,
                          row.employeeNum
                        );
                    }}
                    >
                      {data.statusInfo && (
                          data.statusInfo.map((d, idx: number) => {
                            let elements = [];
                            if (d.statusCode === 'W1000' && d.startTime) {
                              elements.push(
                                <div key={0 + idx} className="time-to-work">
                                  <p>출근</p>
                                  {d.startTime}
                                </div>
                              );
                            }
                            if (d.statusCode === 'W1000' && d.endTime) {
                              elements.push(
                                <div key={1 + idx} className="time-to-home">
                                  <p>퇴근</p>
                                  {d.endTime}
                                </div>
                              );
                            }
                            if (d.statusCode === 'H1000') {
                              return (
                                <div
                                  key={2 + idx}
                                  className="day-off"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <p>연차</p>
                                  <p style={{ fontWeight: '500' }}>
                                    {d.statusActionType === 'A' && '종일'}
                                    {d.statusActionType === 'H' && '반차(전)'}
                                    {d.statusActionType === 'T' && '반차(후)'}
                                  </p>
                                  {/* <p style={{ fontSize: '8px' }}>
                                  {d.startTime}-{d.endTime}
                                </p> */}
                                </div>
                              );
                            }
                            if (d.statusCode === 'H2000') {
                              return (
                                <div
                                  key={3 + idx}
                                  className="day-off"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <p>월차</p>
                                  <p style={{ fontWeight: '500' }}>
                                    {d.statusActionType === 'A' && '종일'}
                                    {d.statusActionType === 'H' && '반차(전)'}
                                    {d.statusActionType === 'T' && '반차(후)'}
                                  </p>
                                  {/* <p style={{ fontSize: '8px' }}>
                                  {d.startTime}-{d.endTime}
                                </p> */}
                                </div>
                              );
                            }
                            if (d.statusCode === 'H3000') {
                              return (
                                <div
                                  key={4 + idx}
                                  className="day-off"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <p>대휴</p>
                                  <p style={{ fontWeight: '500' }}>
                                    {d.statusActionType === 'A' && '종일'}
                                    {d.statusActionType === 'H' && '반차(전)'}
                                    {d.statusActionType === 'T' && '반차(후)'}
                                  </p>
                                  {/* <p style={{ fontSize: '8px' }}>
                                  {d.startTime}-{d.endTime}
                                </p> */}
                                </div>
                              );
                            }
                            return elements;
                          }
                      ))}
                    </TdTimeInfo>
                  );
                })}
              </TrForBody>
            ))}
          </tbody>
        </Tables>
      </TableWrap>
    </>
  );
};

const Bar = styled.div`
  border-top: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const TableWrap = styled.div`
  ${({ theme }) => theme.stopDrag};
  ${({ theme }) => theme.scrollBarStyle};

  width: 100%;
  overflow-x: auto;
`;

const Tables = styled.table`
  background-color: white;
  width: 100%;
  table-layout: fixed;
  font-size: 13px;
`;

const THead = styled.thead`
  background-color: white;
  height: 60px;
  font-weight: 500;
`;

export const ThEmployeeNameHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  width: 170px; /* [MEMO] 이름 너비 */
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  color: ${({ theme }) => theme.fontColor.darkGray};

  position: sticky; /* sticky 적용을 위한 코드 */
  left: 0; /* sticky 적용을 위한 코드 */
  background-color: white; /* sticky 적용을 위한 코드 */
`;

export const ThTotalWorkingTimeHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  width: 80px; /* [MEMO] 총 근무시간 너비 */
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  /**
   * sticky 적용을 위한 코드 - border-right 주석 처리:
   * sticky 적용을 위해 ThTotalWorkingTimeHeader에 div 요소를 추가하고 div 요소 내에 border-right를 적용했기에,
   * 이곳의 border-right는 주석 처리함.
   * sticky 적용이 필요 없다면 div 요소 자체를 제거한 후, 이곳의 border-right를 활성화 할 것.
   */
  /* border-right: solid 1px ${({ theme }) =>
    theme.backgroundColor.shadowGray}; */
  color: ${({ theme }) => theme.fontColor.darkGray};

  position: sticky; /* sticky 적용을 위한 코드 */
  left: 170px; /* sticky 적용을 위한 코드, ThEmployeeNameHeader의 너비가 170px */
  background-color: white; /* sticky 적용을 위한 코드 */

  div {
    /**
      * sticky 적용을 위한 코드
      * 1-1. ThTotalWorkingTimeHeader 내 div 요소:
      * 오직 sticky 적용을 위해 필요한 요소. sticky 적용이 필요 없다면 div 요소 자체를 제거할 것.
      * 1-2. div 요소 내 border-right, height:
      * border-right 스타일을 가시화하기 위해 넣은 것
     */
    height: 60px;
    border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  }
`;

const ThDateHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  width: 93px; /* [MEMO] 날짜 너비 */
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  /**
   * sticky 적용을 위한 코드 - border-right:
   * 기존의 border-left를 삭제한 후 border-right 스타일을 적용함.
   * sticky 적용 필요 없다면 (border-right 삭제 후) border-left를 적용해도 됨.
   * + border-right 삭제할 경우 last-date 내 border-right 스타일도 제거할 것.
   */
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  color: ${({ theme }) => theme.fontColor.lightGray};
  font-size: 11px;
  font-weight: 400;

  &.last-date {
    border-right: none;
  }

  div {
    margin-bottom: 5px;
    color: ${({ theme }) => theme.fontColor.darkGray};
    font-size: 18px;
    font-weight: 500;

    &.sunday {
      color: ${({ theme }) => theme.pointColor.red};
    }
  }

  p {
    &.sunday {
      color: ${({ theme }) => theme.pointColor.red};
    }
  }
`;

const TrForBody = styled.tr`
  /* 바디에 있는 td 요소(바디에 있는 셀들) 높이 설정 */
  height: 75px;
`;

const TdEmployeeName = styled.td`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  /* text-align: center; */ /* 테이블 셀 중앙정렬 */
  text-align: start;
  padding-left: 30px;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  color: ${({ theme }) => theme.fontColor.darkGray};
  font-weight: 500;

  position: sticky; /* sticky 적용을 위한 코드 */
  left: 0; /* sticky 적용을 위한 코드 */
  background-color: white; /* sticky 적용을 위한 코드 */
`;

const TdTotalWorkingTime = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  /* sticky 적용을 위한 코드 -  border-right 주석 처리:
   * sticky 적용을 위해 주석처리 함.
   * sticky 미적용을 원할 경우, div 요소 내 border-right를 지운 후, 이곳에 border-right를 활성화 시키면 됨
   */
  /* border-right: solid 1px ${({ theme }) =>
    theme.backgroundColor.shadowGray}; */
  color: white;
  font-weight: 500;

  position: sticky; /* sticky 적용을 위한 코드 */
  left: 170px; /* sticky 적용을 위한 코드, TdEmployeeName의 너비가 170px */
  background-color: white; /* sticky 적용을 위한 코드 */

  div {
    display: flex;
    justify-content: start;
    align-items: center;

    /**
      * sticky 적용을 위한 코드- div 요소 내 height, border-right:
      * border-right 스타일을 가시화하기 위해 넣은 것.
      * sticky 적용 필요 없다면 불필요.
     */
    height: 75px;
    border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};

    p {
      padding: 4px 6px;
      border-radius: 0.5em;
      background-color: ${({ theme }) => theme.statusColor.working};
    }
  }
`;

export const TdTimeInfo = styled.td`
  padding: 5px;
  padding-top: 0;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  /**
   * sticky 적용을 위한 코드 - border-right:
   * border-left대신 border-right에 스타일을 적용함.
   * sticky 적용 필요 없다면 (border-right 삭제 후) border-left를 적용해도 됨.
   * + border-right 삭제할 경우 &.last-date 내 border-right 스타일도 제거할 것.
   */
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  font-size: 12px;
  line-height: 1.5;

  &.last-date {
    border-right: none;
  }

  div {
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 3px;
    font-weight: 500;

    &.time-to-work {
      /* [MEMO] 출근 태그 */
      display: flex;
      margin-top: 5px;
      background: ${({ theme }) => theme.statusColor.startWorkBackground};
      color: ${({ theme }) => theme.fontColor.darkGray};

      p {
        margin-right: 5px;
        font-weight: 600;
      }
    }

    &.time-to-home {
      /* [MEMO] 퇴근 태그 */
      display: flex;
      margin-top: 5px;
      background: ${({ theme }) => theme.statusColor.endWorkBackground};
      color: ${({ theme }) => theme.fontColor.darkGray};

      p {
        margin-right: 5px;
        font-weight: 600;
      }
    }

    &.working {
      /* [MEMO] 근무 태그 */
      display: flex;
      margin-top: 5px;
      background-color: ${({ theme }) => theme.statusColor.working};
      color: white;

      p {
        margin-right: 5px;
        font-weight: 600;
      }
    }

    &.day-off {
      /* [MEMO] 각종 연차 태그(연차, 월차, 반차 등) */
      display: flex;
      margin-top: 5px;
      background-color: ${({ theme }) => theme.statusColor.dayOffBackground};
      color: ${({ theme }) => theme.fontColor.darkGray};

      p {
        margin-right: 5px;
        font-weight: 600;
      }
    }
  }
`;
