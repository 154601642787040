// [MEMO] 주간 스케줄러 컴포넌트
/* eslint-disable prefer-const */
import React, { useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { minutesToHours } from '../../../../lib/utils/minutesToHours';
import { onLoadWorkRecordInfo } from '../../utils/onLoadWorkRecordInfo'; // 태그 기록 불러오기
import { onLoadDetailWorkInfo } from '../../utils/onLoadDetailWorkInfo'; // 근무 상세 기록 불러오기

import { RightClickModalAtExistent } from '../../RightClickModal/RightClickModalAtExistent'; // 우클릭 모달 - 수정 관련
import { RightClickModalAtEmpty } from '../../RightClickModal/RightClickModalAtEmpty'; // 우클릭 모달 - 신규 등록 관련
import { WorkRecordModal } from '../../WorkRecordModal/WorkRecordModal';
import { ModifyModal } from '../../ModifyModal/ModifyModal'; // 근무 시간 수정 관련 모달
import { AddModal } from '../../AddModal/AddModal'; // 근무 시간 추가 관련 모달

interface SchedulerProps {
  employeeData: any[];
  apartmentContractIdx: string; // 🚨수정 구현중-임의로 추가
  calYear: string;
  calMonth: string;
  calDate: string;
  setIsUpdateStatus: Dispatch<SetStateAction<boolean>>;
}

export const Scheduler = ({
  employeeData,
  apartmentContractIdx, // 🚨수정 구현중-임의로 추가
  calYear,
  calMonth,
  calDate,
  setIsUpdateStatus,
}: SchedulerProps) => {
  //console.log('::::: 주간employeeData::::: ', employeeData);

  // [MEMO] 👇 headerArray: 헤더 & 목표 날짜가 포함된 주의 정보(해당 주의 년, 월, 일, 요일)
  let headerArray: {
    year: number;
    month: number;
    date: number;
    day: string;
    fulldate: string;
  }[] = [];

  /**
   * 변수명 변경
   * dataArray -> processedEmployeeData
   * (dataArray에 속한) dayArray -> dayData
   */
  let processedEmployeeData: {
    dayData: {
      date?: number | undefined;
      employeeNum?: string | undefined; // 근무자 사번
      timeToWork?: string | undefined; // 출근
      timeToHome?: string | undefined;
      workingDate?: string | undefined;
      workingTime?: string | undefined;
      isNextDateTimeToHome?: boolean | undefined; // [MEMO] 퇴근 날이 다음 날짜인지 확인 여부 (true: 다음 날 퇴근)
      statusCode?: string | undefined; // 🍖이후
    }[];
    employeeName?: string | undefined;
    totalWorkingTime?: any; // [MEMO] 총 근무시간
  }[] = [];

  let dateNumberList: number[] = []; // [MEMO] 표에 적용할 월~토에 해당하는 날짜 7개
  let startDateOfTargetWeek: Date; // [MEMO] 목표 주의 시작 날짜(=일요일 날짜)
  let endDateOfTargetWeek: Date; // [MEMO] 목표 주의 마지막 날짜(=토요일 날짜)

  /**
   * [MEMO] yearOfSunday, monthOfSunday, dateOfSunday:
   * 일요일 날짜(=주의 첫번째 날짜)의 년, 월, 일 정보
   * '근무 시간 수정' 기능을 위해 필요한 정보
   * 한 주에 서로 다른 달이 겹치는 경우, 주의 첫째날을 기준으로
   * 목표 날짜(=근무 시간을 추가하려는 날짜)의 년, 월을 판단함
   */
  let yearOfSunday = 0;
  let monthOfSunday = 0;
  let dateOfSunday = 0;

  // [MEMO] 👇 목표하는 날짜(사용자가 달력에서 선택하는 날짜) / 입력 예: '2023-05-04'
  const targetDate = new Date(`${calYear}-${calMonth}-${calDate}`);

  // [MEMO] 👇 목표하는 날짜가 포함된 주의 일요일 날짜 (Date객체)
  const sundayDateOfTargetWeekObject = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate() - targetDate.getDay() + 1);
  const sundayDateOfTargetWeekAgoObject = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate() - targetDate.getDay() - 1);

  // [MEMO] 👇 목표하는 날짜가 포함된 주의 일요일 날짜 (문자열로 변환) / 결과 예: '2023-04-30'
  const sundayDateOfTargetWeekString = sundayDateOfTargetWeekObject.toISOString().substring(0, 10);
  const sundayDateOfTargetWeekAgoString = new Date(+sundayDateOfTargetWeekAgoObject + 32400000).toISOString().substring(0, 10);

  /**
   * [MEMO] calTargetWeek():
   * 헤더 생성을 위한 함수
   * =특정 날짜에 따른, 해당 특정 날짜가 포함된 주의 정보(년, 월, 일)를 가져오는 함수
   */

  function calTargetWeek() {

    //  [MEMO] 👇 일요일 날짜(=주의 첫번째 날짜)의 년, 월, 일 정보
    /*
    const yearOfSunday = Number(sundayDateOfTargetWeekString.slice(0, 4));
    const monthOfSunday = Number(sundayDateOfTargetWeekString.slice(5, 7));
    const dateOfSunday = Number(sundayDateOfTargetWeekString.slice(-2));
    */
    yearOfSunday = Number(sundayDateOfTargetWeekString.slice(0, 4));
    monthOfSunday = Number(sundayDateOfTargetWeekString.slice(5, 7));
    dateOfSunday = Number(sundayDateOfTargetWeekString.slice(-2));

    // [MEMO] 👇 목표하는 날짜가 포함된 주의 토요일 날짜 (Date객체)
    const saturdayDateOfTargetWeekObject = new Date(
      targetDate.getFullYear(),
      targetDate.getMonth(),
      targetDate.getDate() - targetDate.getDay() + 7
    );

    // [MEMO] 👇 목표하는 날짜가 포함된 주의 토요일 날짜 (문자열로 변환) / 결과 예: '2023-08-05'
    const saturdayDateOfTargetWeekString = saturdayDateOfTargetWeekObject.toISOString().substring(0, 10);

    // [MEMO] 👇 토요일 날짜(=주의 마지막 날짜)의 년, 월, 일 정보
    /*
    const yearOfSaturday = Number(
      saturdayDateOfTargetWeekString.slice(0, 4)
    );
    const monthOfSaturday = Number(
      saturdayDateOfTargetWeekString.slice(5, 7)
    );
    const dateOfSaturday = Number(saturdayDateOfTargetWeekString.slice(-2));
    */

    // [MEMO] 👇 주의 첫번째 날짜(=일요일 날짜)
    startDateOfTargetWeek = new Date(sundayDateOfTargetWeekString);

    // [MEMO] 👇 주의 마지막 날짜(=토요일 날짜)
    endDateOfTargetWeek = new Date(saturdayDateOfTargetWeekString);

    let currentDate = startDateOfTargetWeek;
    headerArray.splice(0, headerArray.length);
    while (currentDate <= endDateOfTargetWeek) {
      const DAYS = ['일', '월', '화', '수', '목', '금', '토'];
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const date = currentDate.getDate();
      const day = DAYS[currentDate.getDay()];
      const fulldate = year + "-" + String(month).padStart(2, "0") + "-" + String(date).padStart(2, "0") ;
      const dateInfo = {
        year: year,
        month: month,
        date: date,
        day: day,
        fulldate : fulldate,
      };
      headerArray.push(dateInfo);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    //console.log('headerArray',headerArray);
    //console.log('sundayDateOfTargetWeekAgoString', sundayDateOfTargetWeekAgoString, sundayDateOfTargetWeekAgoObject.toISOString());

    // [MEMO] 👇 calDateNumberList(): 표에 적용할 월~토에 해당하는 날짜 7개 배열로 추출
    function calDateNumberList() {
      const startDate = new Date(sundayDateOfTargetWeekString);
      const endDate = new Date(saturdayDateOfTargetWeekString);

      for (
        let date = startDate;
        date <= endDate;
        date.setDate(date.getDate() + 1)
      ) {
        const fullDateString = date.toISOString().substring(0, 10); // MEMO: yyyy-mm-dd 형태
        const dateNumber = Number(fullDateString.substring(8, 10));

        dateNumberList.push(dateNumber);
      }
    }
    calDateNumberList();
  }
  calTargetWeek();

  function loadEmployeeData() {
    //console.log('::::: Work Data::::: ', employeeData);
    employeeData.map(item => {
      let employeeDayArray: {
        date?: number;
        workingData?: string;
        timeToWork?: string; // 출근
        timeToHome?: string;
        workingTime?: string;
        isNextDateTimeToHome?: boolean; // 퇴근 날이 다음 날짜인지 체크 (true: 다음날 퇴근)
      }[] = [];

      let totalWorkingTime = 0; // MEMO: 총 근무 시간

      if (item.WorkingDate.length > 0) {
        let idx = 0;
        let isNextWorkToHome = {
          isNextWork :false,
          statusIdx: '',
          statusCode: '',
          statusActionType: '',
          statusName: '',
          startTime: '',
          endTime: '',
        };
        for ( let processDate = 0; processDate < dateNumberList.length; processDate++)
        {
          let resultDate;
          // 전달 말일 출근, 당월 1일 퇴근 근무 처리 부분
          if ( sundayDateOfTargetWeekAgoString == item.WorkingDate[idx].WorkingDate)
          {
            //console.log('이전 주 말일 데이터 있음', item.UserNameKR, idx, item.WorkingDate[idx]);
            resultDate = {
              date: dateNumberList[processDate],
              timeToWork: item.WorkingDate[idx].WorkingData[0].TriggerTimeStart, //출근
              timeToHome: item.WorkingDate[idx].WorkingData[0].TriggerTimeEnd, // 퇴근
              workingDate: item.WorkingDate[idx].WorkingDate, // 근무 기준일
              workingTime: item.WorkingDate[idx].WorkingTime, // 근무시간
              isNextDateTimeToHome: false, // 퇴근 날이 다음 날짜인지 체크 (true: 다음날 퇴근)
              statusIdx: item.WorkingDate[idx].WorkingData[0].idx, // 근무 수정 관련 속성 - 상태 고유 값🚨수정 구현중-임의로 추가
              // statusCode:
              // item.WorkingDate[idx].WorkingData[0].AttendStatusCode, // 근무 수정 관련 속성 - 상태 코드🚨수정 구현중-임의로 추가
              statusCode: item.WorkingDate[idx].WorkingData.map( d => d.AttendStatusCode ), // 근무 수정 관련 속성 - 상태 코드🚨수정 구현중-임의로 추가
              statusName: item.WorkingDate[idx].WorkingData.map( d => d.AttendStatusName ), // 근무 수정 관련 속성 - 상태 이름🚨수정 구현중-임의로 추가
              statusInfo: (function () {
                return item.WorkingDate[idx].WorkingData.map(d => ({
                  statusIdx: d.idx,
                  statusCode: d.AttendStatusCode,
                  statusActionType: d.Working,
                  statusName: d.AttendStatusName,
                  startTime: d.TriggerTimeStart,
                  endTime: d.TriggerTimeEnd,
                }));
              })(),

              workingType: item.WorkingDate[idx].WorkingData[0].Working, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
              statusTardy: item.WorkingDate[idx].WorkingData[0].StatusTardy, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
              statusLeave: item.WorkingDate[idx].WorkingData[0].StatusLeave, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
              changedRecord: item.WorkingDate[idx].WorkingData[0].ChangedRecord, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
              deleteRecord: item.WorkingDate[idx].WorkingData[0].DeleteRecord, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
              yearOfSunday: yearOfSunday, // 🚨🚨🚨추가 날짜 처리 개선중-임의로 추가
              monthOfSunday: monthOfSunday, // 🚨🚨🚨추가 날짜 처리 개선중-임의로 추가
              dateOfSunday: dateOfSunday, // 🚨🚨🚨추가 날짜 처리 개선중-임의로 추가
            };
            for (let statusIdx = 0; statusIdx < resultDate.statusInfo.length; statusIdx++)
            {
              if ((resultDate.statusInfo[statusIdx].statusCode == 'W1000') && (resultDate.statusInfo[statusIdx].startTime != ''))
              {
                isNextWorkToHome.isNextWork = true;
                isNextWorkToHome.statusIdx = resultDate.statusInfo[statusIdx].statusIdx;
                isNextWorkToHome.statusCode = resultDate.statusInfo[statusIdx].statusCode;
                isNextWorkToHome.statusActionType = resultDate.statusInfo[statusIdx].statusActionType;
                isNextWorkToHome.statusName = resultDate.statusInfo[statusIdx].statusName;
                isNextWorkToHome.startTime = resultDate.statusInfo[statusIdx].startTime;
                isNextWorkToHome.endTime = resultDate.statusInfo[statusIdx].endTime;
                resultDate.statusInfo[statusIdx].endTime = '';
              }
            }
            if (idx < item.WorkingDate.length - 1) {
              idx++;
            }
          }

          if ( dateNumberList[processDate] === Number(item.WorkingDate[idx].WorkingDate.slice(-2)))
          {
            // 👇 totalWorkingTime: 총 근무 시간
            totalWorkingTime = totalWorkingTime + Number(item.WorkingDate[idx].WorkingTime);
            resultDate = {
              date: dateNumberList[processDate],
              timeToWork: item.WorkingDate[idx].WorkingData[0].TriggerTimeStart, //출근
              timeToHome: item.WorkingDate[idx].WorkingData[0].TriggerTimeEnd, // 퇴근
              workingDate: item.WorkingDate[idx].WorkingDate, // 근무 기준일
              workingTime: item.WorkingDate[idx].WorkingTime, // 근무시간
              isNextDateTimeToHome: false, // 퇴근 날이 다음 날짜인지 체크 (true: 다음날 퇴근)
              statusIdx: item.WorkingDate[idx].WorkingData[0].idx, // 근무 수정 관련 속성 - 상태 고유 값🚨수정 구현중-임의로 추가
              // statusCode:
              // item.WorkingDate[idx].WorkingData[0].AttendStatusCode, // 근무 수정 관련 속성 - 상태 코드🚨수정 구현중-임의로 추가
              statusCode: item.WorkingDate[idx].WorkingData.map( d => d.AttendStatusCode ), // 근무 수정 관련 속성 - 상태 코드🚨수정 구현중-임의로 추가
              statusName: item.WorkingDate[idx].WorkingData.map( d => d.AttendStatusName ), // 근무 수정 관련 속성 - 상태 이름🚨수정 구현중-임의로 추가
              statusInfo: (function () {
                return item.WorkingDate[idx].WorkingData.map(d => ({
                  statusIdx: d.idx,
                  statusCode: d.AttendStatusCode,
                  statusActionType: d.Working,
                  statusName: d.AttendStatusName,
                  startTime: d.TriggerTimeStart,
                  endTime: d.TriggerTimeEnd,
                }));
              })(),

              workingType: item.WorkingDate[idx].WorkingData[0].Working, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
              statusTardy: item.WorkingDate[idx].WorkingData[0].StatusTardy, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
              statusLeave: item.WorkingDate[idx].WorkingData[0].StatusLeave, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
              changedRecord: item.WorkingDate[idx].WorkingData[0].ChangedRecord, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
              deleteRecord: item.WorkingDate[idx].WorkingData[0].DeleteRecord, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
              yearOfSunday: yearOfSunday, // 🚨🚨🚨추가 날짜 처리 개선중-임의로 추가
              monthOfSunday: monthOfSunday, // 🚨🚨🚨추가 날짜 처리 개선중-임의로 추가
              dateOfSunday: dateOfSunday, // 🚨🚨🚨추가 날짜 처리 개선중-임의로 추가
            };

            if(isNextWorkToHome.isNextWork == true)
            {
              //console.log('WorkingDate: ', item.WorkingDate[idx].WorkingDate);
              //console.log('isNextWorkToHome: ', isNextWorkToHome);
              //console.log('isNextWorkToHome.statusIdx: ', isNextWorkToHome.statusIdx);
              resultDate.statusInfo.unshift({
                statusIdx: isNextWorkToHome.statusIdx,
                statusCode: isNextWorkToHome.statusCode,
                statusActionType: isNextWorkToHome.statusActionType,
                statusName: isNextWorkToHome.statusName,
                startTime: '',
                endTime: isNextWorkToHome.endTime,
              }),
              isNextWorkToHome.isNextWork = false;
              isNextWorkToHome.statusIdx = '';
              isNextWorkToHome.statusCode = '';
              isNextWorkToHome.statusActionType = '';
              isNextWorkToHome.statusName = '';
              isNextWorkToHome.startTime = '';
              isNextWorkToHome.endTime = '';
            }
            if(item.WorkingDate[idx].WorkingData[0].TriggerStart.slice(0,10) == item.WorkingDate[idx].WorkingData[0].TriggerEnd.slice(0,10)) {
              resultDate.isNextDateTimeToHome = false;
            } else {
              resultDate.isNextDateTimeToHome = true;
              for (let statusIdx = 0; statusIdx < resultDate.statusInfo.length; statusIdx++)
              {
                if ((resultDate.statusInfo[statusIdx].statusCode == 'W1000') && (resultDate.statusInfo[statusIdx].startTime != ''))
                {
                  isNextWorkToHome.isNextWork = true;
                  isNextWorkToHome.statusIdx = resultDate.statusInfo[statusIdx].statusIdx;
                  isNextWorkToHome.statusCode = resultDate.statusInfo[statusIdx].statusCode;
                  isNextWorkToHome.statusActionType = resultDate.statusInfo[statusIdx].statusActionType;
                  isNextWorkToHome.statusName = resultDate.statusInfo[statusIdx].statusName;
                  isNextWorkToHome.startTime = resultDate.statusInfo[statusIdx].startTime;
                  isNextWorkToHome.endTime = resultDate.statusInfo[statusIdx].endTime;
                  resultDate.statusInfo[statusIdx].endTime = '';
                  //console.log('isNextWorkToHome: ', isNextWorkToHome);
                  //console.log('statusInfo: ', resultDate.statusInfo[statusIdx]);
                }
              }
            }
            if (idx < item.WorkingDate.length - 1) {
              idx++;
            }
            employeeDayArray.push(resultDate);
          } else {
            if(isNextWorkToHome.isNextWork == true)
            {
              resultDate = {
                date: dateNumberList[processDate],
                statusName: '근무없음',
                timeToWork: '',
                timeToHome: '',
                workingDate: headerArray[processDate].fulldate, // 근무 기준일
                statusInfo: [{
                  statusIdx: isNextWorkToHome.statusIdx,
                  statusCode: isNextWorkToHome.statusCode,
                  statusActionType: isNextWorkToHome.statusActionType,
                  statusName: isNextWorkToHome.statusName,
                  startTime: '',
                  endTime: isNextWorkToHome.endTime,
                }],
                workingTime: '',
                yearOfSunday: yearOfSunday, // 🚨🚨🚨추가 날짜 처리 개선중-임의로 추가
                monthOfSunday: monthOfSunday, // 🚨🚨🚨추가 날짜 처리 개선중-임의로 추가
                dateOfSunday: dateOfSunday, // 🚨🚨🚨추가 날짜 처리 개선중-임의로 추가
                isNextMonth:  dateOfSunday > dateNumberList[processDate] ? true : false, // 🚨🚨🚨추가 날짜 처리 개선중-임의로 추가
              };
              isNextWorkToHome.isNextWork = false;
              isNextWorkToHome.statusIdx = '';
              isNextWorkToHome.statusCode = '';
              isNextWorkToHome.statusActionType = '';
              isNextWorkToHome.statusName = '';
              isNextWorkToHome.startTime = '';
              isNextWorkToHome.endTime = '';

            }
            else
            {
              resultDate = {
                date: dateNumberList[processDate],
                statusName: '근무없음',
                timeToWork: '',
                timeToHome: '',
                workingDate: headerArray[processDate].fulldate, // 근무 기준일
                workingTime: '',
                yearOfSunday: yearOfSunday, // 🚨🚨🚨추가 날짜 처리 개선중-임의로 추가
                monthOfSunday: monthOfSunday, // 🚨🚨🚨추가 날짜 처리 개선중-임의로 추가
                dateOfSunday: dateOfSunday, // 🚨🚨🚨추가 날짜 처리 개선중-임의로 추가
                isNextMonth:  dateOfSunday > dateNumberList[processDate] ? true : false, // 🚨🚨🚨추가 날짜 처리 개선중-임의로 추가
              };
            }
            employeeDayArray.push(resultDate);
          }
        }
        idx = 0;
      } else {
        for (
          let processDate = 0;
          processDate < dateNumberList.length;
          processDate++
        ) {
          let resultDate = {
            date: dateNumberList[processDate],
            statusName: '근무없음',
            timeToWork: '',
            timeToHome: '',
            workingDate: headerArray[processDate].fulldate, // 근무 기준일
            workingTime: '',
            yearOfSunday: yearOfSunday, // 🚨🚨🚨추가 날짜 처리 개선중-임의로 추가
            monthOfSunday: monthOfSunday, // 🚨🚨🚨추가 날짜 처리 개선중-임의로 추가
            dateOfSunday: dateOfSunday, // 🚨🚨🚨추가 날짜 처리 개선중-임의로 추가
            isNextMonth: dateOfSunday > dateNumberList[processDate] ? true : false, // 🚨🚨🚨추가 날짜 처리 개선중-임의로 추가
          };
          //console.log('2# Scheduler - dateNumberList : ', dateNumberList[processDate]);
          //console.log('2# Scheduler - resultDate : ', resultDate);
          employeeDayArray.push(resultDate);
        }
      }

      let result = {
        employeeName: item.UserNameKR,
        dayData: employeeDayArray,
        totalWorkingTime: totalWorkingTime, // 총 근무시간
        employeeNum: item.EmployeeNo, // 🚨수정 구현중-임의로 추가
      };

      processedEmployeeData.push(result);
    });
  }

  loadEmployeeData();

  //console.log('🍖주간🍖employeeData: ', employeeData);
  //console.log('🍖주간🍖processedEmployeeData: ', processedEmployeeData);

  // [TAG] 👇 근무 시간 추가 -----start
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [rightClickModalVisibleAtEmpty, setRightClickModalVisibleAtEmpty] = useState(false);
  type isLeaveReportData = { isLeave : boolean | false; idx: number | 0; employeeNum: string | ''};
  const [isLeaveReport, setIsLeaveReport] = useState<isLeaveReportData | null>(null);

  const closeAddModal = () => {
    setAddModalVisible(false);
  };
  const onRightClick = (
    e: React.MouseEvent,
    row: any,
    data: any,
    statusIdx: string,
    employeeNum: string
  ) => {
    //console.log('onRightClick - row: ', row);
    //console.log('onRightClick - data: ', data);
    //console.log('onRightClick - statusIdx: ', statusIdx);
    //console.log('onRightClick - employeeNum: ', employeeNum);

    setRowInfo(row);
    setCellData(data);
    setTargetWorkStatusIdx(statusIdx);
    setTargetEmployeeNum(employeeNum);
    onLoadWorkRecordInfo({
      employeeNum,
      apartmentContractIdx,
      date : data.workingDate,
      workRecordData,
      setWorkRecordData,
      setOnWorkRecordModal,
    });
    onLoadDetailWorkInfo({
      setDetailWorkData,
      statusIdx,
      employeeNum,
    });
    const position = { x: e.clientX, y: e.clientY };
    setRightClickModalPosition(position);
    if(data.statusInfo)
    {
      setIsLeaveReport({isLeave : false, idx: 0, employeeNum: '0'});
      for (let i = 0; i < data.statusInfo.length; i++) {
        if (data.statusInfo[i].statusActionType =='A' || data.statusInfo[i].statusActionType =='H')
        {
          setIsLeaveReport({isLeave : true, idx: data.statusInfo[i].statusIdx, employeeNum: employeeNum});
          //console.log('Right Click - data : Action, Idx , isLeaveReport', data.statusInfo[i].statusActionType, data.statusInfo[i].statusIdx, isLeaveReport);
        }
      }
    }
    if(statusIdx)
    {
      setRightClickModalVisibleAtExistent(true);
    }
    else
    {
      setRightClickModalVisibleAtEmpty(true);
    }
  };

  // [TAG] 👇 근무 시간 수정 -----start
  const [modifyModalVisible, setModifyModalVisible] = useState(false);
  const [
    rightClickModalVisibleAtExistent,
    setRightClickModalVisibleAtExistent,
  ] = useState(false);

  const closeModifyModal = () => {
    setModifyModalVisible(false);
  };

  // [TAG] 👇 근태 기록 확인 -----start
  const [onWorkRecordModal, setOnWorkRecordModal] = useState(false);
  const [workRecordData, setWorkRecordData] = useState([]);

  const closeModal = () => {
    setOnWorkRecordModal(false);
  };
  // [TAG] 👆 근태 기록 확인-----end

  const [detailWorkData, setDetailWorkData] = useState([]);
  const [rowInfo, setRowInfo] = useState([]);
  const [cellData, setCellData] = useState({}); // 셀에 담긴 정보
  const [rightClickModalPosition, setRightClickModalPosition] = useState({
    x: 0,
    y: 0,
  });
  const [targetEmployeeNum, setTargetEmployeeNum] = useState('');
  const [targetWorkStatusIdx, setTargetWorkStatusIdx] = useState<string | null>(
    null
  );

  const handleRightClickModal = () => {
    setRightClickModalVisibleAtExistent(false);
    setRightClickModalVisibleAtEmpty(false);
  };

  const handleStatusBarClick = (statusIdx: string, employeeNum: string) => {

    setTargetWorkStatusIdx((statusIdx ? statusIdx : '0'));
    setTargetEmployeeNum(employeeNum);
    //console.log('handleStatusBarClick - statusIdx', (statusIdx ? statusIdx : '0'));
    //console.log('handleStatusBarClick- employeeNum', employeeNum);

    onLoadDetailWorkInfo({
      setDetailWorkData,
      statusIdx,
      employeeNum,
    });
  };

  // -----
  const [selectedStatusIdx, setSelectedStatusIdx] = useState('');
  // console.log('💚주간1💚selectedStatusIdx::: ', selectedStatusIdx);
  // console.log('💚주간2💚detailWorkData::: ', detailWorkData);

  return (
    <>
      {rightClickModalVisibleAtExistent && (
        <RightClickModalAtExistent
          closeModal={handleRightClickModal}
          setOnWorkRecordModal={setOnWorkRecordModal}
          setModifyModalVisible={setModifyModalVisible}
          position={rightClickModalPosition}
          workRecordData={workRecordData}
          detailWorkData={detailWorkData}
          rowInfo={rowInfo}
          isLeave={isLeaveReport}
          setAddModalVisible={setAddModalVisible}
        />
      )}

      {rightClickModalVisibleAtEmpty && (
        <RightClickModalAtEmpty
          closeModal={handleRightClickModal}
          setAddModalVisible={setAddModalVisible}
          position={rightClickModalPosition}
          detailWorkData={detailWorkData}
          dailySection='0'
          rowInfo={rowInfo}
        />
      )}

      {modifyModalVisible && (
        <ModifyModal
          closeModal={closeModifyModal}
          setRightClickModalVisible={setRightClickModalVisibleAtExistent}
          setIsUpdateStatus={setIsUpdateStatus}
          detailWorkData={detailWorkData}
          employeeNum={targetEmployeeNum}
          targetWorkStatusIdx={selectedStatusIdx}
          selectedStatusIdx={selectedStatusIdx}
          setSelectedStatusIdx={setSelectedStatusIdx}
          cellData={cellData}
        />
      )}

      {addModalVisible && (
        <AddModal
          closeModal={closeAddModal}
          setRightClickModalVisible={setRightClickModalVisibleAtEmpty}
          setIsUpdateStatus={setIsUpdateStatus}
          detailWorkData={detailWorkData}
          cellData={cellData}
          employeeNum={targetEmployeeNum}
          targetWorkStatusIdx={targetWorkStatusIdx}
          calYear={calYear}
          calMonth={calMonth}
        />
      )}

      {onWorkRecordModal && (
        workRecordData.length > 0 ?
        (
          <WorkRecordModal
            closeModal={closeModal}
            workRecordData={workRecordData}
          />
        ) : (
          alert('태그 기록이 없습니다')
        )
      )}
      <Bar />
      <TableWrap>
        <Tables>
          <THead>
            <tr>
              <ThEmployeeNameHeader>{/* 근무자 */}</ThEmployeeNameHeader>
              <ThTotalWorkingTimeHeader>
                {/* 총 근무시간 */}
                <div />
              </ThTotalWorkingTimeHeader>

              {headerArray.map((data, index) => (
                <ThTimeHeader
                  key={index}
                  className={`${index === 6 ? 'last-date' : ''}`}
                >
                  {data.day === '일' ? (
                    <>
                      <div className="sunday">{data.date}</div>
                      <p className="sunday">{data.day}</p>
                    </>
                  ) : (
                    <>
                      <div>{data.date}</div>
                      <p>{data.day}</p>
                    </>
                  )}
                </ThTimeHeader>
              ))}
            </tr>
          </THead>

          <tbody>
            {processedEmployeeData.map(row => (
              //<>
                <TrForBody key={row.employeeName}>
                  <TdEmployeeName key={row.employeeName}>{row.employeeName}</TdEmployeeName>

                  {/* 👇 총 근무시간 */}
                  <TdTotalWorkingTime key={row.totalWorkingTime}>
                    {row.totalWorkingTime > 0 ? (
                      <div key={row.totalWorkingTime}>
                        <p key={row.totalWorkingTime}>{minutesToHours(row.totalWorkingTime)} h</p>
                      </div>
                    ) : (
                      <div key={row.totalWorkingTime} />
                    )}
                  </TdTotalWorkingTime>
                  {/* 👆 총 근무시간 */}

                  {row.dayData.map((data, dayDataIndex) => (
                    <TdTimeInfo
                      key={dayDataIndex}
                      className={`${dayDataIndex === 6 ? 'last-date' : ''}`}
                      onClick={() => handleStatusBarClick(data.statusIdx, row.employeeNum )}
                      onContextMenu={e => {e.preventDefault(), onRightClick(e, row, data, data.statusIdx, row.employeeNum );}}
                    >
                      {data.statusInfo && (
                        data.statusInfo.map((d, idx: number) => {
                          let elements = [];

                          if (d.statusCode === 'W1000' && d.startTime) {
                            elements.push(
                              <div key={0 + idx} className="time-to-work">
                                <p>출근</p>
                                {d.startTime}
                              </div>
                            );
                          }
                          if (d.statusCode === 'W1000' && d.endTime) {
                            elements.push(
                              <div key={1 + idx} className="time-to-home">
                                <p>퇴근</p>
                                {d.endTime}
                              </div>
                            );
                          }
                          if (d.statusCode === 'H1000') {
                            return (
                              <div
                                key={2 + idx}
                                className="day-off"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <p>연차</p>
                                <p style={{ fontWeight: '500' }}>
                                  {d.statusActionType === 'A' && '종일'}
                                  {d.statusActionType === 'H' && '반차(전)'}
                                  {d.statusActionType === 'T' && '반차(후)'}
                                </p>
                                {/* <p style={{ fontSize: '8px' }}>
                                  {d.startTime}-{d.endTime}
                                </p> */}
                              </div>
                            );
                          }
                          if (d.statusCode === 'H2000') {
                            return (
                              <div
                                key={3 + idx}
                                className="day-off"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <p>월차</p>
                                <p style={{ fontWeight: '500' }}>
                                  {d.statusActionType === 'A' && '종일'}
                                  {d.statusActionType === 'H' && '반차(전)'}
                                  {d.statusActionType === 'T' && '반차(후)'}
                                </p>
                                {/* <p style={{ fontSize: '8px' }}>
                                  {d.startTime}-{d.endTime}
                                </p> */}
                              </div>
                            );
                          }
                          if (d.statusCode === 'H3000') {
                            return (
                              <div
                                key={4 + idx}
                                className="day-off"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <p>대휴</p>
                                <p style={{ fontWeight: '500' }}>
                                  {d.statusActionType === 'A' && '종일'}
                                  {d.statusActionType === 'H' && '반차(전)'}
                                  {d.statusActionType === 'T' && '반차(후)'}
                                </p>
                                {/* <p style={{ fontSize: '8px' }}>
                                  {d.startTime}-{d.endTime}
                                </p> */}
                              </div>
                            );
                          }

                          return elements;
                        })
                      )}
                    </TdTimeInfo>
                  ))}
                </TrForBody>
              //</>
            ))}
          </tbody>
        </Tables>
      </TableWrap>
    </>
  );
};

const Bar = styled.div`
  border-top: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const TableWrap = styled.div`
  ${({ theme }) => theme.stopDrag};
  ${({ theme }) => theme.scrollBarStyle};

  width: 100%;
  overflow-x: auto;
`;

const Tables = styled.table`
  background-color: white;
  width: 100%;
  table-layout: fixed;
  font-size: 13px;
`;

const THead = styled.thead`
  background-color: white;
  height: 60px;
  font-weight: 500;
`;

export const ThEmployeeNameHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  width: 170px; /* [MEMO] 이름 너비 */
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  color: ${({ theme }) => theme.fontColor.darkGray};

  position: sticky; /* sticky 적용을 위한 코드 */
  left: 0; /* sticky 적용을 위한 코드 */
  background-color: white; /* sticky 적용을 위한 코드 */
`;

export const ThTotalWorkingTimeHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  width: 80px; /* [MEMO] 총 근무시간 너비 */
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  /**
   * sticky 적용을 위한 코드 - border-right 주석 처리:
   * sticky 적용을 위해 ThTotalWorkingTimeHeader에 div 요소를 추가하고 div 요소 내에 border-right를 적용했기에,
   * 이곳의 border-right는 주석 처리함.
   * sticky 적용이 필요 없다면 div 요소 자체를 제거한 후, 이곳의 border-right를 활성화 할 것.
   */
  /* border-right: solid 1px ${({ theme }) =>
    theme.backgroundColor.shadowGray}; */
  color: ${({ theme }) => theme.fontColor.darkGray};

  position: sticky; /* sticky 적용을 위한 코드 */
  left: 170px; /* sticky 적용을 위한 코드, ThEmployeeNameHeader의 너비가 170px */
  background-color: white; /* sticky 적용을 위한 코드 */

  div {
    /**
      * sticky 적용을 위한 코드
      * 1-1. ThTotalWorkingTimeHeader 내 div 요소:
      * 오직 sticky 적용을 위해 필요한 요소. sticky 적용이 필요 없다면 div 요소 자체를 제거할 것.
      * 1-2. div 요소 내 border-right, height:
      * border-right 스타일을 가시화하기 위해 넣은 것
     */
    height: 60px;
    border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  }
`;

const ThTimeHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  width: 150px; /* [MEMO] 날짜 너비 */

  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  /**
   * sticky 적용을 위한 코드 - border-right:
   * 기존의 border-left를 삭제한 후 border-right 스타일을 적용함.
   * sticky 적용 필요 없다면 (border-right 삭제 후) border-left를 적용해도 됨.
   * + border-right 삭제할 경우 last-date 내 border-right 스타일도 제거할 것.
   */
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  color: ${({ theme }) => theme.fontColor.lightGray};
  font-size: 11px;
  font-weight: 400;

  &.last-date {
    border-right: none;
  }

  div {
    margin-bottom: 5px;
    color: ${({ theme }) => theme.fontColor.darkGray};
    font-size: 18px;
    font-weight: 500;

    &.sunday {
      color: ${({ theme }) => theme.pointColor.red};
    }
  }

  p {
    &.sunday {
      color: ${({ theme }) => theme.pointColor.red};
    }
  }
`;

const TrForBody = styled.tr`
  /* 바디에 있는 td 요소(바디에 있는 셀들) 높이 설정 */
  height: 75px;
`;

const TdEmployeeName = styled.td`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  /* text-align: center; */ /* 테이블 셀 중앙정렬 */
  text-align: start;
  padding-left: 30px;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  color: ${({ theme }) => theme.fontColor.darkGray};
  font-weight: 500;

  position: sticky; /* sticky 적용을 위한 코드 */
  left: 0; /* sticky 적용을 위한 코드 */
  background-color: white; /* sticky 적용을 위한 코드 */
`;

const TdTotalWorkingTime = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  /* sticky 적용을 위한 코드 -  border-right 주석 처리:
   * sticky 적용을 위해 주석처리 함.
   * sticky 미적용을 원할 경우, div 요소 내 border-right를 지운 후, 이곳에 border-right를 활성화 시키면 됨
   */
  /* border-right: solid 1px ${({ theme }) =>
    theme.backgroundColor.shadowGray}; */
  color: white;
  font-weight: 500;

  position: sticky; /* sticky 적용을 위한 코드 */
  left: 170px; /* sticky 적용을 위한 코드, TdEmployeeName의 너비가 170px */
  background-color: white; /* sticky 적용을 위한 코드 */

  div {
    display: flex;
    justify-content: start;
    align-items: center;

    /**
      * sticky 적용을 위한 코드- div 요소 내 height, border-right:
      * border-right 스타일을 가시화하기 위해 넣은 것.
      * sticky 적용 필요 없다면 불필요.
     */
    height: 75px;
    border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};

    p {
      padding: 4px 6px;
      border-radius: 0.5em;
      background-color: ${({ theme }) => theme.statusColor.working};
    }
  }
`;

export const TdTimeInfo = styled.td`
  padding: 5px;
  padding-top: 0;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  /**
   * sticky 적용을 위한 코드 - border-right:
   * border-left대신 border-right에 스타일을 적용함.
   * sticky 적용 필요 없다면 (border-right 삭제 후) border-left를 적용해도 됨.
   * + border-right 삭제할 경우 &.last-date 내 border-right 스타일도 제거할 것.
   */
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  font-size: 12px;
  line-height: 1.5;

  &.last-date {
    border-right: none;
  }

  div {
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 3px;
    font-weight: 500;

    &.time-to-work {
      /* [MEMO] 출근 태그 */
      display: flex;
      margin-top: 5px;
      background: ${({ theme }) => theme.statusColor.startWorkBackground};
      color: ${({ theme }) => theme.fontColor.darkGray};

      p {
        margin-right: 5px;
        font-weight: 600;
      }
    }

    &.time-to-home {
      /* [MEMO] 퇴근 태그 */
      display: flex;
      margin-top: 5px;
      background: ${({ theme }) => theme.statusColor.endWorkBackground};
      color: ${({ theme }) => theme.fontColor.darkGray};

      p {
        margin-right: 5px;
        font-weight: 600;
      }
    }

    &.working {
      /* [MEMO] 근무 태그 */
      display: flex;
      margin-top: 5px;
      background-color: ${({ theme }) => theme.statusColor.working};
      color: white;

      p {
        margin-right: 5px;
        font-weight: 600;
      }
    }

    &.day-off {
      /* [MEMO] 각종 연차 태그(연차, 월차, 반차 등) */
      display: flex;
      margin-top: 5px;
      background-color: ${({ theme }) => theme.statusColor.dayOffBackground};
      color: ${({ theme }) => theme.fontColor.darkGray};

      p {
        margin-right: 5px;
        font-weight: 600;
      }
    }
  }
`;
