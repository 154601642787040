// [MEMO] 주간 근무현황 상태바 우클릭시 나타나는 메뉴 모달 컴포넌트
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

//import MoreTimeIcon from '@mui/icons-material/MoreTime';
//import EditIcon from '@mui/icons-material/Edit';
//import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import PostAddIcon from '@mui/icons-material/PostAdd';
//import EventBusyIcon from '@mui/icons-material/EventBusy';

interface RightClickModalAtExistentProps {
  closeModal: React.MouseEventHandler<HTMLDivElement> | undefined;
  setOnWorkRecordModal: Dispatch<SetStateAction<boolean>>;
  setModifyModalVisible: Dispatch<SetStateAction<boolean>>;
  position: { x: number; y: number };
  workRecordData: any;
  detailWorkData: any;
  rowInfo: any;
  isLeave:  { isLeave : boolean | false; idx: number | 0; employeeNum: string | ''};
  setAddModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const RightClickModalAtExistent = ({
  closeModal,
  setOnWorkRecordModal,
  setModifyModalVisible,
  position,
  workRecordData,
  detailWorkData,
  rowInfo,
  isLeave,
  setAddModalVisible,
}: RightClickModalAtExistentProps) => {
  // [TAG] 권한 관련 -----start
  /**
   * permitCode 권한 코드
   * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
   * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
   * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
   */
  const permitCode = sessionStorage.getItem('permitCode');
  const isHeadOfficeManagerCode = permitCode === process.env.HEAD_OFFICE_MANAGER_CODE;

  // [TAG] 권한 관련 -----end

  //console.log('Right Click - isLeave', isLeave);
  //console.log('Right Click - rowInfo', rowInfo);
  //console.log('Right Click - detailWorkData', detailWorkData);
  //console.log('Right Click - workRecordData', workRecordData);
  //console.log('Right Click - position', position);

  const modalStyle = {
    top: position.y + 'px',
    left: position.x + 'px',
  };

  const handleInfoModal = () => {
    if (workRecordData.length <= 0) {
      return alert('관련 데이터가 존재하지 않습니다');
    }

    setOnWorkRecordModal(true);
  };

  const handleAddModal = () => {
    if (detailWorkData.length <= 0) {
      return alert('일정 추가는 "주간/월간" 조회에서 가능합니다.');
    }
    else
    {
      setAddModalVisible(true);
    }
  };

  const handleModifyModal = () => {
    if (detailWorkData.length <= 0) {
      return alert('일정 수정은 "주간/월간" 조회에서 가능합니다.');
    }
    else
    {
      setModifyModalVisible(true);
    }
  };

  const handleLeaveReportModal = () => {
    if (detailWorkData.length <= 0) {
      return alert('휴가원 인쇄는 "주간/월간" 조회에서 가능합니다.');
    }
    else
    {
      const dataToSend = 'leave-report-printing';
      const dataObject = {
        idx: isLeave.idx,
        empno: isLeave.employeeNum,
      };

      const url = `/${dataToSend}?dataObject=${encodeURIComponent(
        JSON.stringify(dataObject)
      )}`;

      const childWindow = window.open(
        url,
        '_blank',
        'width=820px, height=1000px, toolbar=no, location=no, status=no'
      );

      if (childWindow) {
        childWindow.postMessage({ dataObject }, window.origin);
      }
    }
  };

  return (
    <Background onClick={closeModal}>
      <Wrap
        className="modal"
        style={modalStyle}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Box>
          {isHeadOfficeManagerCode ? (
            <MenuButton
              // disabled={workRecordData.length > 0 ? false : true}
              className={
                workRecordData.length > 0 ? `info-menu on` : `info-menu off`
              }
              onClick={() =>
                // workRecordData.length > 0 ? handleInfoModal() : ''
                handleInfoModal()
              }
            >
              <AutoFixHighIcon
                sx={{
                  fontSize: '18px',
                }}
              />
              {/* 근태 세부 기록 */}
              <p style={{ marginLeft: '2px' }}>근태 기록 확인</p>
            </MenuButton>
          ) : (
            ''
          )}
          {/* {isHeadOfficeManagerCode && workRecordData.length > 0 && (
            <MenuButton className="info-menu" onClick={() => handleInfoModal()}>
              태그 기록 확인
            </MenuButton>
          )} */}

          <MenuButton className="add-menu" onClick={() => handleAddModal()}>
            <EventAvailableIcon
              sx={{
                fontSize: '18px',
              }}
            />
            <p style={{ marginLeft: '2px' }}>일정 추가</p>
          </MenuButton>

          <MenuButton className="modify-menu" onClick={() => handleModifyModal()}>
            <EditCalendarIcon
              sx={{
                fontSize: '18px',
              }}
            />
            <p style={{ marginLeft: '2px' }}>일정 수정/삭제</p>
          </MenuButton>
          {isLeave.isLeave && (
          <MenuButton className="leavereport-menu" onClick={() => handleLeaveReportModal()}>
            <PostAddIcon
              sx={{
                fontSize: '18px',
              }}
            />
            <p style={{ marginLeft: '2px' }}>휴가원 인쇄</p>
          </MenuButton>
          )}
        </Box>
      </Wrap>
    </Background>
  );
};

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 101;
`;

const Wrap = styled.div`
  position: fixed;
  z-index: 100;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;

  /* padding: 8px; */
  padding: 5px;
  padding-top: 0;
  border-radius: 0.5em;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  background-color: white;
`;

const MenuButton = styled.button`
  ${({ theme }) => theme.printBtnStyle}

  display:flex;
  align-items: center;
  margin-top: 5px;
  width: 110px;

  &.info-menu {
    margin-bottom: 5px;
  }

  &.info-menu.off {
    /* cursor: default; */

    :hover {
      /* background-color: #ededed; */
    }
  }
`;
