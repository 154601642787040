// [MEMO] 월 근무현황 인쇄
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { onWorkStatusLoadBtnMonthly } from '../utils/onWorkStatusLoadBtnMonthly';
import { ApprovalList } from '../../SP10-020-approval-table-setting/ApprovalList/ApprovalList'; // [MEMO] 결재 박스

import { read, utils, writeFileXLSX, WorkSheet } from 'xlsx'; // 🎅🎅 SheetJS
//console.log = console.warn = console.error = () => {};
//console.log = console.warn = console.error = () => {};
console.clear();
//console.warn('Something bad happened.');
//console.error('Something bad happened.');
/**
 * permitCode 권한 코드
 * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
 * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
 * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
 */

const MonthlyPrinting = () => {
  /* 👇 🥺🥺🥺🥺🥺 */
  const queryParams = new URLSearchParams(window.location.search);
  const receivedObject = JSON.parse(queryParams.get('dataObject'));

  //console.log('🍕receivedObject: ', receivedObject);
  /* 👆 🥺🥺🥺🥺🥺 */

  /* 👇 🥺🥺🥺🥺🥺 */
  const [employeeDataMonthly, setEmployeeDataMonthly] = useState<any[]>([]);

  const [monthlySchedulerLoading, setMonthlySchedulerLoading] = useState(true);
  const [monthlyPrintingLoading, setMonthlyPrintingLoading] = useState(true);
  const [personalWorkCardLoading, setPersonalWorkCardLoading] = useState(true);

  const employeeData = employeeDataMonthly;
  const calYear = receivedObject.calYear;
  const calMonth = receivedObject.calMonth;

  useEffect(() => {
    onWorkStatusLoadBtnMonthly({
      setEmployeeDataMonthly,
      apartmentContractIdx: receivedObject.apartmentContractIdx, // 아파트 계약 인덱스
      calYear: receivedObject.calYear,
      calMonth: receivedObject.calMonth,
      calPlanned: receivedObject.calPlanned,
      setMonthlySchedulerLoading,
      setMonthlyPrintingLoading, // [MEMO] 인쇄 관련
      setPersonalWorkCardLoading, // [MEMO] 인쇄 관련
    });
  }, []);
  /* 👆 🥺🥺🥺🥺🥺 */

  const [isListData, setIsListData] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const token = sessionStorage.getItem('token');
  const permitCode = sessionStorage.getItem('permitCode');
  const headOfficerManagerCode = process.env.HEAD_OFFICE_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentManagerCode = process.env.APARTMENT_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentNameAtSessionStorage = sessionStorage.getItem('apartmentName');

  // 👇👇👇 데이터 받기(useLocation)
  // const dataReceived = location.state; // 기존 (startDate, endDate없이 단독으로 받을때)
  // const employeeData = location.state.employeeData;
  // const calYear = location.state.calYear;
  // const calMonth = location.state.calMonth;
  // 👆👆👆 데이터 받기(useLocation)

  const DAYS = ['일', '월', '화', '수', '목', '금', '토'];

  /**
   * [MEMO] targetMonth:
   * Date객체의 월 인덱스는 0부터 시작하기 때문에, 데이터 피커에서 가져 온 달에서 1을 빼야 함
   */
  const targetYear = Number(calYear);
  const targetMonth = Number(calMonth) - 1;

  const date = new Date(targetYear, targetMonth);

  // const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1).getDay();

  const lastDateOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

  const lastDayOfPreMonth = new Date(date.getFullYear(), date.getMonth(), 0 ).getDate();
  const lastDateOfMonthAgo = new Date(date.setMonth(date.getMonth() - 1));	// 한달 전
  const lastDateOfPreMonth = lastDateOfMonthAgo.getFullYear() + '-' + (('0' + (lastDateOfMonthAgo.getMonth() + 1)).slice(-2)) + '-' + lastDayOfPreMonth.toString();

  // [MEMO] headerArray: 헤더 & 화면에 나타나는 달 정보(날짜, 요일)
  const headerArray: {
    date: number;
    day: string;
  }[] = [];

  for (let i = 1; i <= lastDateOfMonth; i++) {
    const dayOfMonth = new Date(date.getFullYear(), date.getMonth(), i).getDay();

    headerArray.push({
      date: i,
      day: DAYS[dayOfMonth],
    });
  }
  // console.log('월 근무현황 인쇄 - 월간/headerArray: ', headerArray);

  /**
   * 변수명 변경
   * dataArray -> processedEmployeeData
   * (dataArray에 속한) dayArray -> dayData
   */

  const processedEmployeeData: {
    dayData: {
      date?: number;
      timeToWork?: string;
      timeToHome?: string;
      workingData?: string;
      workingTime?: string;
      isNextDateTimeToHome?: string; // 퇴근 날이 다음 날짜인지 체크 (true: 다음날 퇴근)
    }[];
    employeeName?: string;
    employeeNum?: string;
    totalWorkingTime?: any; // [MEMO] 총 근무시간
  }[] = [];

  function loadEmployeeData() {
    employeeData.map(item => {
      const employeeDayArray: {
        date?: number;
        timeToWork?: string;
        timeToHome?: string;
        workingTime?: string;
      }[] = [];

      let totalWorkingTime = 0; // [MEMO] 총 근무시간

      if (item.WorkingDate.length > 0) {
        let idx = 0;
        let isNextWorkToHome = {
          isNextWork :false,
          statusIdx: '',
          statusCode: '',
          statusActionType: '',
          statusName: '',
          startTime: '',
          endTime: '',
        };
        for (let processDate = 0; processDate < lastDateOfMonth; processDate++)
        {
          let resultDate;
          resultDate = {
            date: processDate + 1,
            timeToWork: item.WorkingDate[idx].WorkingData[0].TriggerTimeStart, //출근
            timeToHome: item.WorkingDate[idx].WorkingData[0].TriggerTimeEnd, // 퇴근
            workingTime: item.WorkingDate[idx].WorkingTime, // 근무시간
            isNextDateTimeToHome: false, // 퇴근 날이 다음 날짜인지 체크 (true: 다음날 퇴근)
            statusInfo: (function () {
              return item.WorkingDate[idx].WorkingData.map(d => ({
                statusIdx: d.idx,
                statusCode: d.AttendStatusCode,
                statusActionType: d.Working,
                statusName: d.AttendStatusName,
                startTime: d.TriggerTimeStart,
                endTime: d.TriggerTimeEnd,
              }));
            })(),
            workingType: item.WorkingDate[idx].WorkingData[0].Working, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
            statusTardy: item.WorkingDate[idx].WorkingData[0].StatusTardy, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
            statusLeave: item.WorkingDate[idx].WorkingData[0].StatusLeave, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
            changedRecord: item.WorkingDate[idx].WorkingData[0].ChangedRecord, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
            deleteRecord: item.WorkingDate[idx].WorkingData[0].DeleteRecord, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
          };
          // 전달 말일 출근, 당월 1일 퇴근 근무 처리 부분
          if ( lastDateOfPreMonth == item.WorkingDate[idx].WorkingDate)
          {
            //console.log('이전 달 말일 데이터 있음', item.UserNameKR, idx, item.WorkingDate[idx]);
            for (let statusIdx = 0; statusIdx < resultDate.statusInfo.length; statusIdx++)
            {
              if ((resultDate.statusInfo[statusIdx].statusCode == 'W1000') && (resultDate.statusInfo[statusIdx].startTime != ''))
              {
                isNextWorkToHome.isNextWork = true;
                isNextWorkToHome.statusIdx = resultDate.statusInfo[statusIdx].statusIdx;
                isNextWorkToHome.statusCode = resultDate.statusInfo[statusIdx].statusCode;
                isNextWorkToHome.statusActionType = resultDate.statusInfo[statusIdx].statusActionType;
                isNextWorkToHome.statusName = resultDate.statusInfo[statusIdx].statusName;
                isNextWorkToHome.startTime = resultDate.statusInfo[statusIdx].startTime;
                isNextWorkToHome.endTime = resultDate.statusInfo[statusIdx].endTime;
                resultDate.statusInfo[statusIdx].endTime = '';
                //console.log('isNextWorkToHome: ', isNextWorkToHome);
                //console.log('statusInfo: ', resultDate.statusInfo[statusIdx]);
              }
            }
            if (idx < item.WorkingDate.length - 1) {
              idx++;
            }
          }
          if ( processDate + 1 === Number(item.WorkingDate[idx].WorkingDate.slice(-2))) {
            // [MEMO] 👇 총 근무 시간
            totalWorkingTime = totalWorkingTime + Number(item.WorkingDate[idx].WorkingTime);
            // [TAG] 👇 현재 시간 체크를 위한 조건 -----start
            //console.log('StartDate/EndDate: ', item.WorkingDate[idx].WorkingData[0].TriggerStart.slice(0,10), item.WorkingDate[idx].WorkingData[0].TriggerEnd.slice(0,10));
            if(isNextWorkToHome.isNextWork == true)
            {
              resultDate.statusInfo.unshift({
                statusIdx: isNextWorkToHome.statusIdx,
                statusCode: isNextWorkToHome.statusCode,
                statusActionType: isNextWorkToHome.statusActionType,
                statusName: isNextWorkToHome.statusName,
                startTime: '',
                endTime: isNextWorkToHome.endTime,
              }),
              isNextWorkToHome.isNextWork = false;
              isNextWorkToHome.statusIdx = '';
              isNextWorkToHome.statusCode = '';
              isNextWorkToHome.statusActionType = '';
              isNextWorkToHome.statusName = '';
              isNextWorkToHome.startTime = '';
              isNextWorkToHome.endTime = '';
            }
            if(item.WorkingDate[idx].WorkingData[0].TriggerStart.slice(0,10) == item.WorkingDate[idx].WorkingData[0].TriggerEnd.slice(0,10)) {
              resultDate.isNextDateTimeToHome = false;
            } else {
              resultDate.isNextDateTimeToHome = true;
              for (let statusIdx = 0; statusIdx < resultDate.statusInfo.length; statusIdx++)
              {
                if ((resultDate.statusInfo[statusIdx].statusCode == 'W1000') && (resultDate.statusInfo[statusIdx].startTime != ''))
                {
                  isNextWorkToHome.isNextWork = true;
                  isNextWorkToHome.statusIdx = resultDate.statusInfo[statusIdx].statusIdx;
                  isNextWorkToHome.statusCode = resultDate.statusInfo[statusIdx].statusCode;
                  isNextWorkToHome.statusActionType = resultDate.statusInfo[statusIdx].statusActionType;
                  isNextWorkToHome.statusName = resultDate.statusInfo[statusIdx].statusName;
                  isNextWorkToHome.startTime = resultDate.statusInfo[statusIdx].startTime;
                  isNextWorkToHome.endTime = resultDate.statusInfo[statusIdx].endTime;
                  resultDate.statusInfo[statusIdx].endTime = '';
                  //console.log('isNextWorkToHome: ', isNextWorkToHome);
                  //console.log('statusInfo: ', resultDate.statusInfo[statusIdx]);
                }
              }
            }
            if (idx < item.WorkingDate.length - 1) {
              idx++;
            }
          } else {
            if(isNextWorkToHome.isNextWork == true)
            {
              resultDate = {
                date: processDate + 1,
                employeeName: item.UserNameKR, // 🍟 근무자 이름
                employeeNum: item.EmployeeNo, // 🍟 근무자 사번
                statusName: '근무없음',
                statusInfo: [{
                  statusIdx: isNextWorkToHome.statusIdx,
                  statusCode: isNextWorkToHome.statusCode,
                  statusActionType: isNextWorkToHome.statusActionType,
                  statusName: isNextWorkToHome.statusName,
                  startTime: '',
                  endTime: isNextWorkToHome.endTime,
                }],
                timeToWork: '',
                timeToHome: '',
                workingTime: '',
              };
              isNextWorkToHome.isNextWork = false;
              isNextWorkToHome.statusIdx = '';
              isNextWorkToHome.statusCode = '';
              isNextWorkToHome.statusActionType = '';
              isNextWorkToHome.statusName = '';
              isNextWorkToHome.startTime = '';
              isNextWorkToHome.endTime = '';
            }
            else
            {
              resultDate = {
                date: processDate + 1,
                employeeName: item.UserNameKR, // 🍟 근무자 이름
                employeeNum: item.EmployeeNo, // 🍟 근무자 사번
                statusName: '근무없음',
                statusInfo: [],
                timeToWork: '',
                timeToHome: '',
                workingTime: '',
              };
            }
          }
          employeeDayArray.push(resultDate);
        }
        idx = 0;
      } else {
        for (let processDate = 0; processDate < lastDateOfMonth; processDate++)
        {
          let resultDate = {
            date: processDate + 1,
            employeeName: item.UserNameKR, // 🍟 근무자 이름
            employeeNum: item.EmployeeNo, // 🍟 근무자 사번
            timeToWork: '',
            timeToHome: '',
            statusName: '근무없음',
            workingTime: '',
          };
          //console.log('근무 기록 없음 - resultDate: ', resultDate);
          employeeDayArray.push(resultDate);
        }
      }
      const result = {
        dayData: employeeDayArray,
        employeeName: item.UserNameKR,
        employeeNum: item.EmployeeNo,
        totalWorkingTime: totalWorkingTime, // [MEMO] 총 근무시간
      };
      processedEmployeeData.push(result);
    });
  }

  loadEmployeeData();

  //console.log('🍖employeeData: ', employeeData);
  //console.log('🍖processedEmployeeData: ', processedEmployeeData);

  /* 👇 [TAG] 배열 자르기 */
  // const itemsPerFirstPage = 7; // 첫 페이지에 출력할 항목 수
  // isListData === true -> 결재자가 아무도 없는 상태(결재박스 없는 상태)
  const itemsPerFirstPage = isListData === true ? 10 : 9; // 첫 페이지에 출력할 항목 수
  const itemsPerPage = 11; // (첫 페이지 이후) 한 페이지에 출력할 항목 수

  // 데이터를 페이지당 항목 수로 분할
  const pages = [];
  let pageIndex = 0;

  for (let i = 0; i < processedEmployeeData.length; i++) {
    if (!pages[pageIndex]) {
      pages[pageIndex] = [];
    }
    pages[pageIndex].push(processedEmployeeData[i]);

    // if (pages[pageIndex].length === itemsPerPage) {
    //   pageIndex++;
    // }

    if (pages[pageIndex].length ===(pageIndex === 0 ? itemsPerFirstPage : itemsPerPage)) {
      pageIndex++;
    }
  }
  /* 👆 [TAG] 배열 자르기 */

  /* 👇 excel export 1 -----start 🎅🎅 */
  /* the component state is an HTML string */
  const [__html, setHtml] = useState('');
  /* the ref is used in export */
  const tableRef = useRef(null);

  /* Fetch and update the state once */
  useEffect(() => {
    (async () => {
      const f = await (
        await fetch('https://sheetjs.com/pres.xlsx')
      ).arrayBuffer();
      const wb = read(f); // parse the array buffer
      const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
      const data = utils.sheet_to_html(ws); // generate HTML
      setHtml(data); // update state
    })();
  }, []);

  /* get live table and export to XLSX */
  const exportExcelFile = useCallback(() => {
    const dateList = headerArray.map(data => {
      return { name: data.date };
    });

    const columns = [{name: '이름', }, { name: '구분', }, ...dateList, ];

    const $table = tableRef.current.getElementsByTagName('table')[0];
    //console.log('🥗1🥗tableRef: ', tableRef);
    //console.log('🥗2🥗tableRef.current: ', tableRef.current);
    const $tr = $table.querySelectorAll('tr');
    const columnData = columns.map(column => column.name);
    const rowData = Array.from($tr).map(row => Array.from(row.children).map(cell => cell.textContent || cell.innerText) );
    //console.log('🍖1🍖rowData: ', rowData);
    //console.log( '🍖2🍖Array.from...: ', Array.from($tr).map(row => Array.from(row.children)));

    const header = columnData; //
    const body = rowData;

    const ws: WorkSheet = utils.aoa_to_sheet([header, ...body]);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'WT-HR');

    writeFileXLSX(wb, '월간근무내역.xlsx');
  }, [tableRef]);

  //console.log('🍛🍛🍛processedEmployeeData: ', processedEmployeeData);

  const dataForExcel = () => {
    // processedEmployeeData.map((row, index) => {
    //   const filteredWorkingF = row.dayData.map((data, index) => {
    //     data.statusInfo &&
    //       data.statusInfo.filter(d => d.statusCode === 'W1000');
    //   });
    // });
    // console.log('🚀filteredWorkingF🚀: ', filteredWorkingF);

    return (
      <div style={{ display: 'none' }}>
        <div ref={tableRef}>
          <Tables>
            <tbody key='excel'>
              {processedEmployeeData.map((row, index) => {
                return (
                  <>
                    <tr key={index+10000}>
                      <td key={index+11000}>{row.employeeName}</td>
                      <td key={index+12000}>출</td>
                      {row.dayData.map((data, index) => {
                        const filteredWorking = data.statusInfo && data.statusInfo.filter(d => d.statusCode === 'W1000');
                        return (filteredWorking && ((filteredWorking.length > 0 && filteredWorking[0].startTime) || (filteredWorking.length > 1 && filteredWorking[1].startTime))) ? (
                          <td key={index+12000}>○{data.statusIdx}</td>
                        ) : (
                          <td />
                        );
                      })}
                    </tr>
                    <tr key={index+20000}>
                      <td key={index+21000} />
                      <td key={index+22000}>퇴</td>
                      {row.dayData.map((data, index) => {
                        const filteredWorking = data.statusInfo && data.statusInfo.filter(d => d.statusCode === 'W1000');
                        return (filteredWorking && ((filteredWorking.length > 0 && filteredWorking[0].endTime ) || (filteredWorking.length > 1 && filteredWorking[1].endTime)))? (
                          <td key={index+23000}>○</td>
                        ) : (
                          <td />
                        );
                      })}
                    </tr>
                    <tr key={index+30000}>
                      <td key={index+31000} />
                      <td key={index+32000}>연</td>
                      {row.dayData.map((data, index) => {
                        const filteredDayOff = data.statusInfo && data.statusInfo.filter(d => d.statusCode.includes('H','T'));
                        return (
                          <td key={index}>
                            {filteredDayOff && filteredDayOff.length > 0 && filteredDayOff[0].statusActionType === 'A' ? `${filteredDayOff[0].statusName}(종)` : ''}
                            {filteredDayOff && filteredDayOff.length > 0 && filteredDayOff[0].statusActionType === 'H' ? `${filteredDayOff[0].statusName}(전)` : ''}
                            {filteredDayOff && filteredDayOff.length > 0 && filteredDayOff[0].statusActionType === 'T' ? `${filteredDayOff[0].statusName}(후)` : ''}
                          </td>
                        );
                      })}
                      <td />
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Tables>
        </div>
      </div>
    );
  };
  /* 👆 excel export 1 -----end 🎅🎅 */

  return (
    <>
      <ViewSection>
        <Button className="print" onClick={() => window.print()}>
          인쇄하기
        </Button>
        <Button className="close" onClick={() => window.close()}>
          닫기
        </Button>
        <Button style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={exportExcelFile}>
          근무 내역 내보내기(엑셀)
        </Button>
      </ViewSection>
      {dataForExcel()}
      <PrintSection>
        <TitleSection>
          <TitleBox>
            <div>
              출근부 ({calYear}년 {calMonth}월)
            </div>
          </TitleBox>
          {/* 👇 🚨🚨🚨🚨🚨 결재 박스 🚨🚨🚨🚨🚨 */}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px', width: '100%', }}>
            <ApprovalList setIsListData={setIsListData} />
          </div>
          {/* 👆 🚨🚨🚨🚨🚨 결재 박스 🚨🚨🚨🚨🚨 */}
          <InfoBox>
            <div>
              단지명:
              {permitCode === apartmentManagerCode && (apartmentNameAtSessionStorage ? apartmentNameAtSessionStorage : '')}
              {permitCode === headOfficerManagerCode && (receivedObject.apartmentName ? receivedObject.apartmentName : '')}
            </div>
            <div>주식회사 앰앰아이</div>
          </InfoBox>
        </TitleSection>
        {pages.map((page, pageIndex) => {
          const lastPageIndex = pages.length - 1;
          return (
            <>
              <Tables>
                <thead>
                  <TrForHeader>
                    <ThEmployeeNameHeader>이름</ThEmployeeNameHeader>
                    <ThTotalWorkingTimeHeader>구분</ThTotalWorkingTimeHeader>
                    {headerArray.map((data, index) => {
                      const lastDate = index;
                      return (
                        <ThDateHeader key={data.date} style={ lastDate === index ? { borderRight: 'solid 1px #343a40' } : '' } >
                          <div>{data.date}</div>
                        </ThDateHeader>
                      );
                    })}
                  </TrForHeader>
                </thead>
              </Tables>
              <Tables>
                <tbody>
                  <tr key={pageIndex}>
                    <td>
                      {page.map((row, index) => (
                        <div key={row.employeeNum} style={{ width: '100%' }}>
                          <TdEmployeeName>{row.employeeName}</TdEmployeeName>

                          {/* 👇 구분 */}
                          <TdTotalWorkingTime>
                            <div>
                              <div className="time-to-work">출</div>
                              <div className="time-to-home">퇴</div>
                              <div className="time-to-break">연</div>
                            </div>
                          </TdTotalWorkingTime>
                          {/* 👆 구분 */}

                          {row.dayData.map((data, index) => {
                            const lastDate = index;
                            const filteredWorking = data.statusInfo && data.statusInfo.filter(d => d.statusCode === 'W1000');
                            const filteredDayOff = data.statusInfo && data.statusInfo.filter(d => d.statusCode.includes('H','T'));
                            return (
                              <TdTimeInfo key={data.date} style={lastDate === index ? { borderRight: 'solid 1px #343a40' } : ''}>
                                <StatusResultBox>
                                  {/* 👇 출근 */}
                                  {filteredWorking && (( filteredWorking.length > 0 && filteredWorking[0].startTime) || (filteredWorking.length > 1 && filteredWorking[1].startTime)) ? (
                                    <div className="time-to-work">○</div>
                                  ) : (
                                    <div className="nothing" />
                                  )}
                                  {/* 👇 퇴근 */}
                                  {filteredWorking && ((filteredWorking.length > 0 && filteredWorking[0].endTime) || (filteredWorking.length > 1 && filteredWorking[1].endTime)) ? (
                                    <div className="time-to-home">○</div>
                                  ) : (
                                    <div className="nothing" />
                                  )}
                                  {/* 👇 연차(종일) */}
                                  <div className="time-to-break" >
                                    {filteredDayOff && filteredDayOff.length > 0 && filteredDayOff[0].statusActionType === 'A' ?  `${filteredDayOff[0].statusName.slice(0,1)}` : ''}
                                    {/* 👇 연차(반차-전) */}
                                    {filteredDayOff && filteredDayOff.length > 0 && filteredDayOff[0].statusActionType === 'H' ? `${filteredDayOff[0].statusName.slice(0,1)}`: ''}
                                    {/* 👇 연차(반차-후) */}
                                    {filteredDayOff && filteredDayOff.length > 0 && filteredDayOff[0].statusActionType === 'T' ? `${filteredDayOff[0].statusName.slice(0,1)}`: ''}
                                  </div>
                                </StatusResultBox>
                              </TdTimeInfo>
                            );
                          })}
                        </div>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </Tables>
              {pageIndex + 1} / {pages.length}
              <p style={ pageIndex === lastPageIndex ? { display: 'none' } : { pageBreakBefore: 'always' }}/>
            </>
          );
        })}
      </PrintSection>
    </>
  );
};

export default MonthlyPrinting;

const ViewSection = styled.div`
  position: fixed;
  top: 10px;
  left: 10px;

  /* 인쇄 시에만 출력할 스타일 지정 */
  @media print {
    display: none;
  }
`;

const PrintSection = styled.div`
  background-color: white;
  width: 210mm;
  height: 297mm;
  margin: 0;
  padding: 0.5cm;

  /* 인쇄 시에만 출력할 스타일 지정 */
  @media print {
    display: block;
    width: 210mm;
    height: 297mm;
    margin: 0;
  }

  /* 웹 페이지에서는 보이지 않도록 숨김 처리 */
  /* display: none; */
`;
const Button = styled.button`
  ${({ theme }) => theme.printBtnStyle};

  &.print {
    margin-right: 5px;
    width: 60px;
    height: 30px;
  }

  &.close {
    width: 60px;
    height: 30px;
  }
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 210mm;
  height: 297mm;
  background-color: white;
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  margin-top: 5mm;
  margin-bottom: 5.8mm; /* 🚨🚨🚨🚨🚨 결재 박스 관련(수정 후)2 */
`;

const InfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
`;

const TableWrap = styled.div`
  ${({ theme }) => theme.stopDrag};
  ${({ theme }) => theme.scrollBarStyle};
  color: ${({ theme }) => theme.fontColor.black};
  border: solid 1px ${({ theme }) => theme.fontColor.black};
`;

const Tables = styled.table`
  width: 100%;
`;

const THead = styled.thead`
  font-weight: 400;
  font-size: 8px;
`;

const Tbody = styled.tbody`
  /*  */
`;

const TrForHeader = styled.tr`
  background-color: lightGray;
`;

const ThEmployeeNameHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  /* width: 30px;  */

  border-top: solid 1px ${({ theme }) => theme.fontColor.black};
  border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
  border-right: solid 1px ${({ theme }) => theme.fontColor.black};
  border-left: solid 1px ${({ theme }) => theme.fontColor.black};
  height: 24px; /* ✅ 구분 높이 */

  font-size: 8px;
  font-weight: 400;

  width: auto; /* [MEMO] 이름 너비 */
`;

const ThTotalWorkingTimeHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  /* width: 20px; */
  width: 20px; /* [MEMO] 총 근무시간 너비 */
  height: 24px; /* ✅ 구분 높이 */

  font-size: 8px;
  font-weight: 400;

  border-top: solid 1px ${({ theme }) => theme.fontColor.black};
  border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
  border-right: solid 1px ${({ theme }) => theme.fontColor.black};
`;

const ThDateHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  width: 20px; /* [MEMO] 날짜 너비 */

  height: 24px; /* ✅ 구분 높이 */

  font-size: 10px;
  font-weight: 600;

  border-top: solid 1px ${({ theme }) => theme.fontColor.black};
  border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
  border-left: solid 1px ${({ theme }) => theme.fontColor.black};

  div {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    width: 20px;
  }
`;

const TdEmployeeName = styled.td`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */

  /* width: 30px; */
  width: 100%;

  border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
  border-right: solid 1px ${({ theme }) => theme.fontColor.black};
  border-left: solid 1px ${({ theme }) => theme.fontColor.black};

  font-size: 10px;
  font-weight: 600;

  div {
    width: 30px;
  }
`;

const TestTest = styled.div`
  width: 20px;
`;

const TdTotalWorkingTime = styled.td`
  border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
  border-right: solid 1px ${({ theme }) => theme.fontColor.black};
  /* width: 20px; */
  width: 100%;

  font-size: 8px;
  font-weight: 400;

  div {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 20px;

    &.time-to-work {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: solid 1px ${({ theme }) => theme.fontColor.gray};
      height: 8mm; /* 📌 날짜 높이 */
      /* width: 100%; */
    }

    &.time-to-home {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: solid 1px ${({ theme }) => theme.fontColor.gray};
      height: 8mm; /* 📌 날짜 높이 */
      /* width: 100%; */
    }

    &.time-to-break {
      display: flex;
      justify-content: center;
      align-items: center;
      // border-bottom: solid 1px ${({ theme }) => theme.fontColor.darkGray};
      height: 8mm; /* 📌 날짜 높이 */
      /* width: 100%; */
    }
  }
`;

const TdTimeInfo = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
  border-left: solid 1px ${({ theme }) => theme.fontColor.black};
  /* width: 20px; */
  width: 100%;

  /* div {
    width: 30px;
  } */
`;

const StatusResultBox = styled.div`
  div {
    font-size: 10px;
    font-weight: 600;
    width: 20px;

    &.nothing {
      /* [MEMO] 출근 태그 */
      display: flex;
      justify-content: center;

      border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
      height: 8mm; /* 📌 날짜 높이 */
      /* width: 100%; */
    }

    &.time-to-work {
      /* [MEMO] 출근 태그 */
      display: flex;
      justify-content: center;
      align-items: center;

      border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
      height: 8mm; /* 📌 날짜 높이 */
      /* width: 100%; */
    }

    &.time-to-home {
      /* [MEMO] 퇴근 태그 */
      display: flex;
      justify-content: center;
      align-items: center;

      border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
      height: 8mm; /* 📌 날짜 높이 */
      /* width: 100%; */
    }

    /* working */
    &.time-to-break {
      /* [MEMO] 연차 태그 */
      display: flex;
      justify-content: center;
      align-items: center;

      height: 8mm; /* 📌 높이 */
      /* width: 100%; */
    }
  }
`;
