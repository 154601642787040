import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface onAddDataProps {
  closeModal: () => void;
  setIsUpdateStatus: Dispatch<SetStateAction<boolean>>;
  cellData: any;
  employeeNum: string;
  targetWorkStatusIdx: string | null;
  selectedStartHours: string;
  selectedStartMinutes: string;
  selectedEndHours: string;
  selectedEndMinutes: string;
  calYear: string;
  calMonth: string;
  currentDateToString: string;
  isNextDay: boolean;
  inputNote: string;
  selectedStatusCode: string; // 🔰
  selectedStatusType: string; // 🔰
  selectedStatusIdx: string; // 🔰
}

export const onAddData = async ({
  closeModal,
  setIsUpdateStatus,
  cellData,
  employeeNum,
  targetWorkStatusIdx,
  selectedStartHours,
  selectedStartMinutes,
  selectedEndHours,
  selectedEndMinutes,
  calYear,
  calMonth,
  currentDateToString,
  isNextDay,
  inputNote,
  selectedStatusCode, // 🔰
  selectedStatusType, // 🔰
  selectedStatusIdx, // 🔰
}: onAddDataProps) => {
  //console.log('💥근무💥cellData: ', cellData);
  //console.log('💥근무💥inputNote: ', inputNote);
  //console.log('💥근무💥targetWorkStatusIdx: ', targetWorkStatusIdx);
  //console.log('💥근무💥selectedStatusIdx: ', selectedStatusIdx);
  //console.log('💥근무💥selectedStatusCode: ', selectedStatusCode);
  //console.log('💥근무💥selectedStatusType: ', selectedStatusType);

  if (!selectedStatusType)  {
    alert('근무 유형이 정상적으로 선택되지 않았습니다.\n\n근무 유형이 근무일 경우, 시간을 입력해주세요.\n근무 유형이 연월대휴일 경우 반일/종일을 선택해주세요.\n\n선택할 수 있는 유형이 없으면, 근무 일정을 추가 할 수 없습니다.');
    return;
  }


  const token = sessionStorage.getItem('token');

  function addString(str: string) {
    if (str.length === 1) {
      return '0' + str;
    } else return str;
  }

  /*
  function onCurrentYear() {
    if (cellData.isNextMonth) {
      if (cellData.monthOfSunday + 1 > 12) {
        return (cellData.yearOfSunday + 1).toString();
      }
    }
    return cellData.yearOfSunday;
  }

  function onCurrentMonth() {
    if (cellData.isNextMonth) {
      if (cellData.monthOfSunday + 1 > 12) {
        return '01';
      }
      return addString((cellData.monthOfSunday + 1).toString());
    }
    return addString(cellData.monthOfSunday.toString());
  }
  */
  //console.log('🎃1🎃주간onAddData/cellData.isNextMonth: ', cellData.isNextMonth);
  //console.log('🎃2🎃주간onAddData/cellData.monthOfSunday: ', cellData.monthOfSunday );
  //console.log('🎃2🎃주간onAddData/onCurrentMonth(): ', onCurrentMonth());
  //console.log('⏳1⏳TriggerTimeEnd(selectedStartHours:selectedStartMinutes): ', `${addString(selectedEndHours)}:${addString(selectedEndMinutes)}`);
  //console.log('⏳1⏳년-월-일: ', `${onCurrentYear()}-${onCurrentMonth()}-${currentDateToString}`);

  await axios({
    url: `${process.env.COMMON_URL}/attendance/ac10030/attendancereport/${employeeNum}`,
    method: 'post',
    headers: { tokenId: token },
    data: {
      idx: 0,
      AttendStatusCode: selectedStatusCode,
      AttendStatusName: '',
      Working: selectedStatusType,
      StatusTardy: '',
      StatusLeave: '',
      ChangedRecord: false,
      DeleteRecord: false,
      TriggerDate: cellData.workingDate,
      // [MEMO] TriggerTimeStart: '02:00' 형식,
      TriggerTimeStart: `${addString(selectedStartHours)}:${addString(selectedStartMinutes)}`,
      // [MEMO] TriggerTimeEnd: '12:48' 형식,
      TriggerTimeEnd: `${addString(selectedEndHours)}:${addString(selectedEndMinutes)}`,
      // EndTimeDay: isNextDay,
      EndTimeDay: !isNextDay ? false : true,
      ModifyAvailableTimeStart: '',
      ModifyAvailableTimeEnd: '',
      ModifyEndTimeDay: '',
      AttedNote: inputNote,
    },
  }).then(res => {
    console.log('[AC10-030-REPORT] 근무 현황(주간) 추가 API: ',`${process.env.COMMON_URL}/attendance/ac10030/attendancereport/${employeeNum}`);
    try {
      if (res.data.ErrorCode === 0) {
        return (
          closeModal(), alert('변경이 완료되었습니다'), setIsUpdateStatus(true)
        );
      }else{
        console.log(`ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`);
      }
      incorrectToken(res);
    } catch (e) {
      console.error(e);
      console.log(`ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`);
    }
  });
};
