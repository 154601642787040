import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface onWorkStatusLoadBtnMonthlyProps {
  setEmployeeDataMonthly: Dispatch<SetStateAction<any[]>>;
  apartmentContractIdx: string; // 아파트 계약 인덱스
  calYear: string | null;
  calMonth: string | null;
  calPlanned : string;
  setMonthlySchedulerLoading: Dispatch<SetStateAction<boolean>>;
  setMonthlyPrintingLoading: Dispatch<SetStateAction<boolean>>; // 월 근무현황 인쇄
  setPersonalWorkCardLoading: Dispatch<SetStateAction<boolean>>; // 출퇴근 카드 인쇄
}

export const onWorkStatusLoadBtnMonthly = async ({
  setEmployeeDataMonthly,
  apartmentContractIdx, // 아파트 계약 인덱스
  calYear,
  calMonth,
  calPlanned,
  setMonthlySchedulerLoading,
  setMonthlyPrintingLoading, // 월 근무현황 인쇄
  setPersonalWorkCardLoading, // 출퇴근 카드 인쇄
}: onWorkStatusLoadBtnMonthlyProps) => {
  setMonthlyPrintingLoading(true);

  const token = sessionStorage.getItem('token');
  const contractIdx = sessionStorage.getItem('contractIdx'); // 아파트 계약 인덱스

  if (!apartmentContractIdx) {
    return alert('단지를 선택해주세요');
  }

  //console.log('calPlnned: ', calPlanned);
  setMonthlySchedulerLoading(true);

  await axios
    .get(
      // `/sample/AC10-030-monthly-sample.json`, // ✅ 목데이터 (2023-03, idx: 14)
      `${process.env.COMMON_URL}/attendance/ac10030/attendancestatus/${apartmentContractIdx}?Year=${calYear}&Month=${calMonth}&Planned=${calPlanned}`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      console.log( 'onWorkStatusLoadBtnMonthly(월간 근무현황 API확인): ',`${process.env.COMMON_URL}/attendance/ac10030/attendancestatus/${apartmentContractIdx}?Year=${calYear}&Month=${calMonth}&Planned=${calPlanned}` );
      try {
        if (res.data.ErrorCode === 0) {
          setMonthlySchedulerLoading(false);
          setEmployeeDataMonthly(res.data.Data);
          setMonthlyPrintingLoading(false); // 월 근무현황 인쇄
          setPersonalWorkCardLoading(false); // 출퇴근 카드 인쇄
        }

        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
