// [MEMO] 단지 계약 정보 불러오기
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

/**
 * permitCode 권한 코드
 * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
 * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
 * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
 */

interface onLoadBtnProps {
  apartmentData: any[];
  setApartmentData: Dispatch<SetStateAction<any[]>>;
  apartmentIdx: string;
  requestSelectedAreaCode: string;
  managerEmployeeNum: string;
}

export const onLoadBtn = async ({
  apartmentData,
  setApartmentData,
  apartmentIdx,
  requestSelectedAreaCode, // 지역 코드
  managerEmployeeNum,
}: onLoadBtnProps) => {
  const token = sessionStorage.getItem('token');
  const permitCode = sessionStorage.getItem('permitCode');
  const headOfficerManagerCode = process.env.HEAD_OFFICE_MANAGER_CODE; // 관리자(본사)
  const apartmentManagerCode = process.env.APARTMENT_MANAGER_CODE; // 관리소장

  const requestSelectedManagerEmployeeNum =
    managerEmployeeNum && `&EmployeeNo=${managerEmployeeNum}`;
  const requestSelectedApartmentIdx = apartmentIdx && `&Aptidx=${apartmentIdx}`;

  if (permitCode === headOfficerManagerCode) {
    await axios
      .get(
        `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0${requestSelectedApartmentIdx}&Valid=1${requestSelectedAreaCode}${requestSelectedManagerEmployeeNum}`,
        {
          headers: { tokenId: token },
        }
      )
      .then(res => {
        //console.log('[CM10-010]계약 정보 조회-단지 계약 정보 API', `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0${requestSelectedApartmentIdx}&Valid=1${requestSelectedAreaCode}${requestSelectedManagerEmployeeNum}`);
        try {
          if (res.data.ErrorCode === 0) {
            setApartmentData(res.data.Data);
          }

          incorrectToken(res);
        } catch (e) {
          console.error(e);
          console.log(
            `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
          );
        }
      });
  }

  if (permitCode === apartmentManagerCode) {
    await axios
      .get(
        // Valid=0 -> string / Duplicate=1 -> string => 관리소장
        `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0${requestSelectedApartmentIdx}&Valid=0&Duplicate=0${requestSelectedAreaCode}${requestSelectedManagerEmployeeNum}`,
        {
          headers: { tokenId: token },
        }
      )
      .then(res => {
        //console.log('[CM10-010]계약 정보 조회-단지 계약 정보 API',`${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0${requestSelectedApartmentIdx}&Valid=0&Duplicate=0${requestSelectedAreaCode}${requestSelectedManagerEmployeeNum}`);
        try {
          if (res.data.ErrorCode === 0) {
            setApartmentData(res.data.Data);
          }

          incorrectToken(res);
        } catch (e) {
          console.error(e);
          console.log(
            `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
          );
        }
      });
  }

  // 👇 permitCode에 따른 분기 나누기 전 -----start
  // await axios
  //   .get(
  //     // https://apihr.mmigroup.co.kr:9020/common/contractinfo?ItemPerPage=0&ItemStartPage=0&Name=%EB%8B%A8%EC%A7%80%EC%9D%B4%EB%A6%84&AreaCode=9&EmployeeNo=000000
  //     `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0${requestSelectedApartmentIdx}&Valid=1${requestSelectedAreaCode}${requestSelectedManagerEmployeeNum}`,
  //     {
  //       headers: { tokenId: token },
  //     }
  //   )
  //   .then(res => {
  //     // console.log(
  //     //   '[CM10-010]계약 정보 조회-단지 계약 정보 API',
  //     //   `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0${requestSelectedApartmentIdx}&Valid=1${requestSelectedAreaCode}${requestSelectedManagerEmployeeNum}`
  //     // );
  //     try {
  //       if (res.data.ErrorCode === 0) {
  //         setApartmentData(res.data.Data);
  //       }

  //       incorrectToken(res);
  //     } catch (e) {
  //       console.error(e);
  //       console.log(
  //         `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
  //       );
  //     }
  //   });
  // 👇 permitCode에 따른 분기 나누기 전 -----end
};
