import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as S from './Grid.style';
import axios from 'axios';

import { RootState } from '../../../redux/store/store';
import { selectedAreaCode } from '../../../redux/slice/selectedCategoryAtApartmentSlice';

import { ManagerListModal } from '../../managerSelectModal/ManagerSelectModal';
import { DetailInfoModal } from '../DetailInfoModal/DetailInfoModal';

import { comma } from '../../../lib/utils/comma';
import { onLoadBtn } from '../utils/onLoadBtn';
import { onLoadManagerList } from '../utils/onLoadManagerList';
import { onApartmentSerialNumberList } from '../../../lib/utils/onApartmentSerialNumberList'; // ref가 있는 인덱스 API (=단지 고유 번호(계약 아님))
import { Modal } from '../../apartmentSerialNumberSelectModal/Modal'; // ref가 있는 인덱스 API (=단지 고유 번호(계약 아님))

import { Dropbox } from '../../UI/Dropbox/Short';
import { Button } from '../../UI/Button/Short';
import { Input } from '../../UI/Input/Short';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../../styles/agGridStyle/ag-theme-custom.css';
import { CellDoubleClickedEvent } from 'ag-grid-community';

import { TEMP_AREA_CODE } from '../constants/categories'; // WARNING: 상수값임. 추후 카테고리 데이터 바인딩 할 것.
import { gridOptions } from './gridOptions';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

/**
 * permitCode 권한 코드
 * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
 * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
 * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
 */

export const Grid = () => {
  const token = sessionStorage.getItem('token');
  const permitCode = sessionStorage.getItem('permitCode');
  const apartmentRefIdx = sessionStorage.getItem('apartmentRefIdx'); // ref 있음 = 일반 인덱스, 아파트 고유 인덱스(계약 아님)
  const headOfficerManagerCode = process.env.HEAD_OFFICE_MANAGER_CODE; // 관리자(본사)
  const apartmentManagerCode = process.env.APARTMENT_MANAGER_CODE; // 관리소장

  const dispatch = useDispatch(); // redux toolkit

  const [apartmentData, setApartmentData] = useState<any[]>([]);
  const [apartmentDetailData, setApartmentDetailData] = useState<any[]>([]);
  const [categoryName, setCategoryName] = useState<string>('');
  const [categoryValue, setCategoryValue] = useState<any>();

  // [CATEGORY] 근무 지역
  const requestSelectedAreaCode = useSelector(
    (state: RootState) =>
      state.selectedCategoryAtApartment.selectedAreaCodeValue
  );

  let areaCodeValue = '';

  // [CATEGORY]근무 지역 코드
  if (categoryName === 'areaCode') {
    areaCodeValue = categoryValue ? `&AreaCode=${categoryValue}` : '';
    dispatch(selectedAreaCode(areaCodeValue));
  }

  const [detailInfoLoading, setDetailInfoLoading] = useState(false);
  const [onDetailInfoModal, setOnDetailInfoModal] = useState(false);

  const closeDetailInfoModal = () => {
    setOnDetailInfoModal(false);
  };

  const onDetailedEmployeeInfoClick = (e: CellDoubleClickedEvent) => {
    onDetailedApartmentInfo(e);
    //onApartmentImage(e);
  };

  const onDetailedApartmentInfo = async (e: CellDoubleClickedEvent) => {
    setDetailInfoLoading(true);
    setOnDetailInfoModal(true);
    //console.log('계약 정보 조회 / 단지 : ', e.data);
    const targetApartmentNum = e.data.idx;

    await axios
      .get(
        `${process.env.COMMON_URL}/common/contractinfo/${targetApartmentNum}`,
        {
          headers: { tokenId: token },
        }
      )
      .then(res => {
        try {
          setDetailInfoLoading(false);
          //setImageTargetNum(res.data.Data[0].RefContractInfoIdx);
          setApartmentDetailData(res.data.Data[0]);
          onApartmentImage(res.data.Data[0].RefContractInfoIdx);
        } catch (e) {
          console.error('에러가 발생했습니다:', e);
        }
      });
  };

  const [imageText, setImageText] = useState('');
  //const [imageTargetNum, setImageTargetNum] = useState(0);

  const onApartmentImage = async (e: CellDoubleClickedEvent) => {
    //const targetApartmentNum = e.data.idx;
    const targetApartmentNum = `${permitCode === headOfficerManagerCode?e:apartmentRefIdx}`;
    //console.log('계약 정보 조회 / 단지 이미지 - e: ', e);
    //console.log('계약 정보 조회 / 단지 이미지 - targetApartmentNum: ',targetApartmentNum);
    //console.log('계약 정보 조회 / 단지 이미지 - imageTargetNum: ', imageTargetNum);
    //console.log('계약 정보 조회 / 단지 이미지 - apartmentRefIdx: ', apartmentRefIdx);

    await axios
      .get(
        // `${process.env.COMMON_URL}/common/refcontractinfo/image/${targetApartmentNum}`,
        `${process.env.COMMON_URL}/common/refcontractinfo/image/${targetApartmentNum}`,
        {
          responseType: 'blob',
          headers: { tokenId: token, 'content-type': 'image / png' },
        }
      )
      .then(res => {
        try {
          //console.log('계약 정보 조회 / 단지 이미지 - targetApartmentNum: ', targetApartmentNum);
          //console.log('계약 정보 조회 / 단지 이미지 - 데이터: ', res.data);
          //console.log('계약 정보 조회 / 단지 이미지 - 타입: ', res.data.type);

          // TAG: 바이너리 파일 Blob으로 받기 -----start
          /* 백업용
          const myFile = new File([res.data], `${targetApartmentNum}`);
          const reader = new FileReader();
          reader.onload = ev => {
            const previewImage = String(ev.target?.result);
            setImageText(previewImage); // imageText라는 state에 저장
          };
          reader.readAsDataURL(myFile);
          */
          // TAG: 바이너리 파일 Blob으로 받기 -----end

          if (res.data.type !== 'application/json') {
            // TAG: 바이너리 파일 Blob으로 받기 -----start
            const myFile = new File([res.data], `${apartmentIdx}`);
            const reader = new FileReader();
            reader.onload = ev => {
              const previewImage = String(ev.target?.result);
              setImageText(previewImage); // imageText라는 state에 저장
            };
            reader.readAsDataURL(myFile);
            // TAG: 바이너리 파일 Blob으로 받기 -----end
          } else if (res.data.type === 'application/json') {
            setImageText('');
          }

          if (res.data.Data.ErrorCode !== 0) return;
        } catch (e) {
          //
        }
      });
  };

  const [essentialCategory, setEssentialCategory] = useState(false);

  function onEssentialCategory() {
    setEssentialCategory(!essentialCategory);
  }

  // [TAG] 계약 단지 목록 불러오기 (모달)
  // apartmentUniqueData
  //const [onApartmentModal, setOnApartmentModal] = useState(false); // MEMO: 아파트 계약 정보 리스트가 있는 모달
  const [onModal, setOnModal] = useState(false); // MEMO: 아파트 계약 정보 리스트가 있는 모달
  const [apartmentListData, setApartmentListData] = useState<any[]>([]); // MEMO: 아파트 계약 정보 API

  const [apartmentIdx, setApartmentIdx] = useState(''); // 아파트 리스트 인덱스
  const [apartmentName, setApartmentName] = useState('');

  const closeModal = () => {
    setOnModal(false);
  };

  // [TAG] 관리자 목록 불러오기 (모달)
  const [onManagerModal, setOnManagerModal] = useState(false);
  const [managerData, setManagerData] = useState<any[]>([]);
  const [managerEmployeeNum, setManagerEmployeeNum] = useState(''); // 아파트 리스트 인덱스
  const [managerName, setManagerName] = useState('');

  const closeManagerListModal = () => {
    setOnManagerModal(false);
  };

  const onResetBtn = (e: CellDoubleClickedEvent) => {
    setApartmentIdx('');
    setApartmentName('');
    setManagerName('');
    setManagerEmployeeNum('');
  };

  useEffect(() => {
    onLoadBtn({
      apartmentData,
      setApartmentData,
      requestSelectedAreaCode,
      managerEmployeeNum,
      apartmentIdx,
    });
  }, [apartmentIdx,managerEmployeeNum]);

  useEffect(() => {
    onLoadBtn({
      apartmentData,
      setApartmentData,
      requestSelectedAreaCode,
      managerEmployeeNum,
      apartmentIdx,
    });
  }, [requestSelectedAreaCode]);

//console.log('apartmentRefIdx : ', `${apartmentRefIdx}`);
//console.log('apartmentListData : ', `${apartmentListData}`);
//console.log('apartmentIdx : ', `${apartmentIdx}`);
//console.log('apartmentName : ', `${apartmentName}`);

return (
    <>
      {permitCode === headOfficerManagerCode && onModal && (
        <Modal
          closeModal={closeModal}
          apartmentListData={apartmentListData}
          setApartmentListData={setApartmentListData}
          apartmentIdx={apartmentIdx}
          setApartmentIdx={setApartmentIdx}
          apartmentName={apartmentName}
          setApartmentName={setApartmentName}
        />
      )}

      {/* 🚨 [MEMo] <Modal />: 관리자 목록 모달 */}
      {onManagerModal && (
        <ManagerListModal
          closeManagerListModal={closeManagerListModal}
          managerData={managerData}
          managerEmployeeNum={managerEmployeeNum}
          setManagerEmployeeNum={setManagerEmployeeNum}
          managerName={managerName}
          setManagerName={setManagerName}
        />
      )}

      {/* [MEMO] <Modal />: 그리드 더블클릭시 나타나는 단지 상세정보 모달 */}
      {onDetailInfoModal && (
        <DetailInfoModal
          closeDetailInfoModal={closeDetailInfoModal}
          apartmentDetailData={apartmentDetailData}
          image={imageText}
          detailInfoLoading={detailInfoLoading}
        />
      )}

      <S.Title>계약 정보 조회</S.Title>

      <S.Wrap>
        {permitCode === headOfficerManagerCode && (
          <S.EssentialCategorySection
            className={essentialCategory ? 'on-option' : 'off-option'}
            onClick={() => onEssentialCategory()}
          >
            <S.EssentialCategoryWrap
              className={essentialCategory ? 'on-option' : 'off-option'}
            >
              {essentialCategory ? (
                <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
              ) : (
                <KeyboardArrowRightIcon style={{ fontSize: 20 }} />
              )}
              검색 옵션 선택
            </S.EssentialCategoryWrap>
          </S.EssentialCategorySection>
        )}

        {essentialCategory && (
          <S.CategorySection>
            <S.CategoryWrap>
              <S.CategoryGroup>
                <S.CategoryBox>
                  <Dropbox
                    setCategoryName={setCategoryName}
                    setCategoryValue={setCategoryValue}
                    title="근무 지역"
                    selectedCategoryName="areaCode"
                    contentsList={TEMP_AREA_CODE}
                  />
                </S.CategoryBox>
              </S.CategoryGroup>
            </S.CategoryWrap>
          </S.CategorySection>
        )}

        {permitCode === headOfficerManagerCode && <S.Bar />}

        {permitCode ===headOfficerManagerCode && (
          <S.LoadInfoSection>
            <S.LoadInfoList>
              {/* [MEMO] 👇 선택한 계약 단지 */}
              <S.ApartmentNameBox style={{ marginRight: '5px' }}>
                {/* [MEMO] 👇 화면에 단지 인덱스(계약 인덱스 아님) 보이면 헷갈릴까봐 주석 처리함 */}
                {/* <S.ApartmentIdx style={{ marginRight: '5px' }}>
                {apartmentIdx}
              </S.ApartmentIdx> */}
                <S.ApartmentName>{apartmentName}</S.ApartmentName>
              </S.ApartmentNameBox>

              {/* [MEMO] 👇 선택한 관리자 */}
              <S.ApartmentNameBox>
                <S.ApartmentIdx style={{ marginRight: '5px' }}>
                  {managerEmployeeNum}
                </S.ApartmentIdx>
                <S.ApartmentName>{managerName}</S.ApartmentName>
              </S.ApartmentNameBox>
            </S.LoadInfoList>

            <S.LoadBtnSection>
              <div style={{ marginRight: '5px' }}>
                <Button
                  label="단지 선택"
                  onClick={() =>
                    onApartmentSerialNumberList({
                      apartmentListData,
                      setApartmentListData,
                      setOnModal,
                    })
                  }
                />
              </div>
              <div style={{ marginRight: '5px' }}>
                <Button
                  label="관리자 선택"
                  onClick={() =>
                    onLoadManagerList({
                      setManagerData,
                      setOnManagerModal,
                      // apartmentData,
                      // setApartmentData,
                      // requestSelectedAreaCode,
                    })
                  }
                />
              </div>
              <div style={{ marginRight: '5px' }}>
              <Button label="다시 선택" onClick={e => onResetBtn(e)} />
              </div>
              {/*
              <div style={{ marginRight: '5px' }}>
              <Button
                label="불러오기"
                onClick={() =>
                  onLoadBtn({
                    apartmentData,
                    setApartmentData,
                    requestSelectedAreaCode,
                    managerEmployeeNum,
                    apartmentIdx,
                  })
                }
              />
              </div>
              */}
            </S.LoadBtnSection>
          </S.LoadInfoSection>
        )}

        <S.GridSection>
          <div id="myGrid" className="ag-theme-alpine ag-theme-custom">
            <AgGridReact
              headerHeight={45} // 타이틀 행 높이
              rowHeight={35} // 본문 행 높이
              gridOptions={gridOptions}
              rowData={apartmentData.map(data => {
                return {
                  idx: data.idx,
                  name: data.Name, // 단지명
                  areaName: data.AreaName, // 지역(이름)
                  jobGroupEtc: data.JobGroupEtc, // 계약직군 (=직업 그룹)
                  noEmployee: data.NoEmployee, // 현재 근무 인원
                  noHouse: comma(data.NoHouse), // 세대수
                  manageArea: comma(data.ManageArea), // 관리면적
                  startDate: data.StartDate, // 계약 시작일
                  endDate: data.EndDate, // 계약 종료일
                  employeeNameAccount: data.EmployeeNameAccount, // 담당 회계사
                  employeeNameManager: data.EmployeeNameManager, // 담당 관리자
                };
              })}
              animateRows={true}
              rowDragManaged={true}
              suppressMoveWhenRowDragging={true}
              pagination={true}
              paginationPageSize={15}
              onCellDoubleClicked={(e: CellDoubleClickedEvent) =>
                apartmentData.length
                  ? onDetailedEmployeeInfoClick(e)
                  : alert('없음')
              }
            />
          </div>
        </S.GridSection>
      </S.Wrap>
    </>
  );
};
