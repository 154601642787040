import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';

interface onLoadLeaveReportProps {
  setLeaveReportData: Dispatch<SetStateAction<never[]>>;
  StatusIdx: string;
  employeeNum: string;
}

export const onLoadLeaveReport = async ({
  setLeaveReportData,
  StatusIdx,
  employeeNum,
}: onLoadLeaveReportProps) => {
  if ((!StatusIdx) || (!employeeNum)) {
    return alert('선택된 휴가 데이터가 없습니다.');
  }

  const token = sessionStorage.getItem('token');

  await axios
    .get(
      `${process.env.COMMON_URL}/attendance/ac10030/leaveapplication?attendanceIdx=${StatusIdx}&employeeno=${employeeNum}`,
      //`${process.env.COMMON_URL}/attendance/ac10030/leaveapplication?attendanceIdx=11&employeeno=${employeeNum}`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      //console.log('근무 상세 기록: ', `${process.env.COMMON_URL}/attendance/ac10030/leaveapplication?attendanceIdx=${StatusIdx}&employeeno=${employeeNum}` );
      try {
        setLeaveReportData(res.data.Data[0]);
        console.log('setLeaveReportData(res.data.Data): ', res.data.Data);
        if (res.data.Data.length > 0) {
          return;
        }
        if (!res.data.Data[0].idx) {
          return alert('선택된 휴가 데이터가 없습니다.');
        }
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
