import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import styled from 'styled-components';

import PrivateRoute from './components/PrivateRoute/PrivateRoute';

import { Navbar } from './components/Navbar/Navbar';
import { Sidebar } from './components/Sidebar/Sidebar';
import Login from './pages/Login/Login';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import ChangePasswordPage from './pages/Login/ChangePasswordPage/ChangePasswordPage';
import ChangePhoneNumberPage from './pages/Login/ChangePhoneNumberPage/ChangePhoneNumber';

import MainPage from './pages/MainPage/MainPage';
import PersonalInfoPage from './pages/PersonalInfoPage/PersonalInfoPage';
import EmployeeInfoPage from './pages/EmployeeInfoPage/EmployeeInfoPage';
import EmployeeContractPage from './pages/EmployeeContractPage/EmployeeContractPage';
import ApartmentInfoPage from './pages/ApartmentInfoPage/ApartmentInfoPage';
import ApartmentContractInfoPage from './pages/ApartmentContractInfoPage/ApartmentContractInfoPage';
import WorkStatusEmployeePage from './pages/WorkStatusEmployeePage/WorkStatusEmployeePage';
import WorkStatusPersonPage from './pages/WorkStatusPersonPage/WorkStatusPersonPage';
import WorkHistoryPage from './pages/WorkHistoryPage/WorkHistoryPage';
import WorkLogPage from './pages/WorkLogPage/WorkLogPage';
import WorkAreaSettingPage from './pages/WorkAreaSettingPage/WorkAreaSettingPage'; // "근무 구역 설정"
import ApprovalTableSettingPage from './pages/ApprovalTableSettingPage/ApprovalTableSettingPage'; // "결재선 관리"
import CarRegistManagementPage from './pages/CarRegistManagementPage/CarRegistManagementPage';
import CarParkingManagementPage from './pages/CarParkingManagementPage/CarParkingManagementPage';
import PayslipPage from './pages/PayslipPage/PayslipPage'; // 급여 명세서 CS20-010
import SalaryLedgerPage from './pages/SalaryLedgerPage/SalaryLedgerPage'; // 급여 대장 CS30-020

// 👇 [MEMO] 메인 페이지는 아님(추후 수정해야 함)
import DailySection from './components/AC10-030-work-status-employee/DailySection/DailySection';
import WeeklySection from './components/AC10-030-work-status-employee/WeeklySection/WeeklySection';
import MonthlySection from './components/AC10-030-work-status-employee/MonthlySection/MonthlySection';
// 👆 [MEMO] 메인 페이지는 아님(추후 수정해야 함)

// 👇 [MEMO] 프린트 관련
import MonthlyPrinting from './components/AC10-030-work-status-employee/MonthlyPrinting/MonthlyPrinting';
import PersonalWorkCardPrinting from './components/AC10-030-work-status-employee/PersonalWorkCardPrinting/PersonalWorkCardPrinting';
import LeaveReportPrinting from './components/AC10-030-work-status-employee/LeaveReportPrinting/LeaveReportPrinting';
import WorkRecordReportPrinting from './components/AR10-020-work-history/WorkHistoryReportPrinting/WorkRecordReportPrinting';
import ParkingInfoPrinting from './components/CV10-030-car-parking-management/ParkingInfoPrinting/ParkingInfoPrinting'; // 차량 주차 정보 인쇄
import ParkingViolationPrinting from './components/CV10-030-car-parking-management/ParkingViolationPrinting/ParkingViolationPrinting';
// 👆 [MEMO] 프린트 관련

const Router = () => {
  /**
   *  [MEMO] isSidebarOpen, setIsSidebarOpen:
   * 네브바에 있는 토글 버튼에 상태값을 전달해 사이드바를 제어함
   */
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  function handleSidebar() {
    setIsSidebarOpen(!isSidebarOpen);
  }

  function closeSidebar() {
    setIsSidebarOpen(false);
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/changePassword" element={<ChangePasswordPage />} />
          <Route
            path="/changePhoneNumber"
            element={<ChangePhoneNumberPage />}
          />
          <Route path="/pageNotFound" element={<PageNotFound />} />

          {/* 👇 프린트 관련 */}
          <Route path="/monthly-printing" element={<MonthlyPrinting />} />
          <Route path="/work-record-report-printing" element={<WorkRecordReportPrinting />} />
          <Route path="/personal-work-card-printing" element={<PersonalWorkCardPrinting />} />
          <Route path="/parking-info-printing" element={<ParkingInfoPrinting />} />
          <Route path="/parking-violation-printing" element={<ParkingViolationPrinting />} />
          <Route path="/leave-report-printing" element={<LeaveReportPrinting />} />
          {/* 👆 프린트 관련 */}

          <Route
            path="/*"
            element={
              <>
                <Navbar
                  isSidebarOpen={isSidebarOpen}
                  handleSidebar={handleSidebar}
                />
                <Body>
                  <Sidebar
                    isSidebarOpen={isSidebarOpen}
                    closeSidebar={closeSidebar}
                  />
                  <Contents>
                    <Wrap>
                      <Routes>
                        <Route element={<PrivateRoute authentication={true} />}>
                          <Route path="/" element={<MainPage />} />
                          <Route
                            path="/personalInfo"
                            element={<PersonalInfoPage />}
                          />
                          <Route
                            // path="/employee"
                            path="/SE103"
                            element={<EmployeeInfoPage />}
                          />
                          <Route
                            // path="/employeeContract"
                            path="/CS104"
                            element={<EmployeeContractPage />}
                          />
                          <Route
                            // path="/apartmentInfo"
                            path="/SS102"
                            element={<ApartmentInfoPage />}
                          />
                          <Route
                            // path="/apartmentContract"
                            path="/CM101"
                            element={<ApartmentContractInfoPage />}
                          />
                          <Route
                            // path="/workStatusEmployee"
                            path="/AC103"
                            element={<WorkStatusEmployeePage />}
                          >
                            <Route
                              // path="/workStatusEmployee/dailySection"
                              path="/AC103/dailySection"
                              element={<DailySection />}
                            />
                            <Route
                              // path="/workStatusEmployee/weeklySection"
                              path="/AC103/weeklySection"
                              element={<WeeklySection />}
                            />
                            <Route
                              // path="/workStatusEmployee/monthlySection"
                              path="/AC103/monthlySection"
                              element={<MonthlySection />}
                            />
                          </Route>
                          <Route
                            path="/workStatusPerson"
                            element={<WorkStatusPersonPage />}
                          />
                          <Route
                            // path="/workHistory"
                            path="/AR102"
                            element={<WorkHistoryPage />}
                          />
                          {/* <Route path="/workLog" element={<WorkLogPage />} /> */}
                          <Route path="/AR103" element={<WorkLogPage />} />
                          <Route
                            // path="/workAreaSetting"
                            path="/SA201"
                            element={<WorkAreaSettingPage />}
                          />
                          <Route
                            // path="/approvalTableSetting"
                            path="/SP102"
                            element={<ApprovalTableSettingPage />}
                          />
                          <Route
                            path="/CV102"
                            element={<CarRegistManagementPage />}
                          />
                          <Route
                            path="/CV103"
                            element={<CarParkingManagementPage />}
                          />
                          <Route path="/CS201" element={<PayslipPage />} />
                          <Route path="/CS302" element={<SalaryLedgerPage />} />
                          {/* <Route
                            path="/*"
                            element={<Navigate to="/pageNotFound" />}
                          /> */}
                        </Route>
                      </Routes>
                    </Wrap>
                  </Contents>
                </Body>
              </>
            }
          />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Router;

const Body = styled.div`
  display: flex;
  margin-top: 55px; /* 네브바의 height 감안 */
`;

const Contents = styled.div`
  margin-left: 250px; /* 사이드바의 width 감안 */
  width: 100vw;
  height: calc(100vh - 55px); /* 네브바의 height 감안 */
  background-color: ${({ theme }) => theme.backgroundColor.white};
  transition: 0.2s ease-out;
  min-width: 0; /* 🔥 */
  /* flex: 1; 사용X*/
  /* max-height: 0; 사용X*/

  @media (max-width: 900px) {
    margin-left: 0; /* [MEMO] 사라진 사이드바 자리 채우기 */
    transition: 0.2s ease-out;
  }
`;

const Wrap = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: ${({ theme }) => theme.backgroundColor.white};
`;

// 1. 다른 프로젝트 적용 예시 -----start
/*
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Nav from './components/Nav/Nav';
import Footer from './components/Footer/Footer';
import Main from './pages/Main/Main';
import Company from './pages/Company/Company';

function Router() {
  return (
    <BrowserRouter>
      <Nav />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/company" element={<Company />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default Router;
*/
// 1. 다른 프로젝트 적용 예시 -----end
